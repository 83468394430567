<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="pa-3 shadowCard">
      <v-card-title primary-title>
        Mi Bono Navideño
        <v-spacer></v-spacer>
        <!-- <v-btn 
          color="blue"
          tile
          @click="abrirFirma( )"
          v-if="!firma"
          dark
        > 
          Firmar documento
          <v-icon small right>mdi-pencil</v-icon>
        </v-btn> -->
        <v-alert
          dense
          text
          type="error"
          v-if="firma && firma.estatus == 2 "
        >
          Rechazado
        </v-alert>

        <v-alert
          dense
          text
          type="success"
          v-if="firma && firma.estatus == 1 "
        >
          Aceptado
        </v-alert>

        <v-alert
          dense
          text
          type="info"
          v-if="!firma"
        >
          Pendiente
        </v-alert>
      </v-card-title>

      <v-card-text>
        <v-row dense v-if="firma && aguinaldo">
          <v-col cols="12" md="4">
            <!-- Coorporativo -->
            <v-card class="shadowCard" v-if="[82,10].includes( getdatosUsuario.idpuesto )">
              <v-card-title class="text-subtitle-1">
                Cita:
                <v-spacer></v-spacer>
                <v-icon>mdi-calendar</v-icon>
              </v-card-title>
              <v-card-text class="black--text">
                <b>Fecha:</b> Jueves 19 de Diciembre<br/>
                <b>Horario de atención:</b> 10:00 a 11:00 y 16:00 a 17:00 <br/>
                <b>Asesor:</b> Yahaira Sarahi Mendoza Ruiz<br/>
                <b>NOTA: Traer la credencial de elector</b><br/>
              </v-card-text>
            </v-card> 

            <!-- Coorporativo -->
            <v-card class="shadowCard" v-if="getdatosUsuario.id_plantel == 13 && getdatosUsuario.idpuesto != 10">
              <v-card-title class="text-subtitle-1">
                Cita:
                <v-spacer></v-spacer>
                <v-icon>mdi-calendar</v-icon>
              </v-card-title>
              <v-card-text class="black--text">
                <b>Fecha:</b> Martes 17 de Diciembre<br/>
                <b>Hora:</b> 17:00 <br/>
                <b>Asesor:</b> Yahaira Sarahi Mendoza Ruiz<br/>
              </v-card-text>
            </v-card>  


            <v-card class="shadowCard" v-if="[73,18,78].includes( getdatosUsuario.idpuesto )">
              <v-card-title class="text-subtitle-1">
                Cita:
                <v-spacer></v-spacer>
                <v-icon>mdi-calendar</v-icon>
              </v-card-title>
              <v-card-text class="black--text">
                <b>Fecha:</b> Martes 17 de Diciembre<br/>
                <b>Hora:</b> 17:00 <br/>
                <b>Asesor:</b> Yahaira Sarahi Mendoza Ruiz<br/>
              </v-card-text>
            </v-card>

            <!-- Turno matutinoooo -->
            <v-card class="shadowCard" v-if="[19].includes( aguinaldo.id_puesto ) && aguinaldo.turno == 1">
              <v-card-title class="text-subtitle-1">
                Cita:
                <v-spacer></v-spacer>
                <v-icon>mdi-calendar</v-icon>
              </v-card-title>
              <v-card-text class="black--text">
                <b>Fecha:</b> Martes 17 de Diciembre<br/>
                <b>Hora:</b> 16:00 <br/>
                <b>Asesor:</b> Yahaira Sarahi Mendoza Ruiz<br/>
              </v-card-text>
            </v-card>

            <!-- Turno vespertinoooo -->
            <v-card class="shadowCard" v-if="[19,79,88].includes( aguinaldo.id_puesto ) && aguinaldo.turno == 2">
              <v-card-title class="text-subtitle-1">
                Cita:
                <v-spacer></v-spacer>
                <v-icon>mdi-calendar</v-icon>
              </v-card-title>
              <v-card-text class="black--text">
                <b>Fecha:</b> Martes 17 de Diciembre<br/>
                <b>Hora:</b> 08:00 <br/>
                <b>Asesor:</b> Yahaira Sarahi Mendoza Ruiz<br/>
              </v-card-text>
            </v-card>


          </v-col>
        </v-row>
      </v-card-text>

      <v-row dense>
        <v-col cols="12">
          <v-card class="mb-2 shadowCard">
            <v-card-title></v-card-title>

            <v-card-text class="black--text text-h6" v-if="aguinaldo">
              <b>Trabajador:</b> {{ getdatosUsuario.nombre_completo }}<br/>
              <b>Puesto:</b> {{ format( aguinaldo.puesto ) }}<br/>
              <b>Fecha ingreso:</b> {{ format( aguinaldo.fecha_ingreso ) }}<br/>
              <b>Bono Navideño:</b> {{ format( aguinaldo.aguinaldo ) }}<br/><br/>

              <br/>

              <v-alert
                outlined
                type="error"
                prominent
                border="left"
              >
                ⚠️ Aviso Importante
                <br/>
                <br/>
                La información proporcionada a continuación es <b>confidencial</b> y de uso exclusivo para los fines establecidos. Está estrictamente prohibido su uso indebido, divulgación, reproducción, distribución, así como la captura y filtración de imágenes, capturas de pantalla (screenshots) o mensajes sin autorización expresa.
                <br/>
                <br/>

                En caso de que se detecte alguna violación a esta disposición, se aplicará una sanción de <b>2 millones de pesos</b>, conforme a los criterios establecidos en el reglamento correspondiente y, de ser necesario, con las acciones legales pertinentes.
                <br/>
                <br/>

                <b>Por favor, maneje esta información con la máxima discreción.</b>
              </v-alert>

              <v-alert
                v-if="!firma"
                outlined
                type="warning"
                prominent
                border="left"
              >
                Estimado(a)  {{ getdatosUsuario.nombre_completo }}, <br/>
                El monto indicado en la parte superior será depositado en su cuenta. Le solicitamos, por favor, dar clic en el botón de aceptar para confirmar su conformidad con dicho monto.

                <br/>
                La cita solo será agendada al momento de aceptar su conformidad.
                <br/><br/>
                Atentamente,<br/>
                School of english.
              </v-alert>
            </v-card-text>

            <v-card-text v-if="firma">
              <v-row>
                <v-col cols="7" v-for="(mensaje, i) in firma.comentarios" :key="i" :class="`${mensaje.id_trabajador != getdatosUsuario.id_trabajador ? 'pl-10' : 'pr-10'}`">
                  <p 
                    :class="`pa-2 ma-0 mb-4 ${ mensaje.id_trabajador != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
                    {{ mensaje.motivo }}
                    <br/>
                    {{ mensaje.nombre_completo }}
                  </p>
                </v-col>
              </v-row>

            </v-card-text>

            <v-card-actions v-if="!firma || ( firma && firma.estatus == 0 )">
              <v-btn
                color="black"
                @click="dialogRechazo = true"        
                class="mr-2"
                tile
                dark
              >
                Rechazar
                <v-icon small right>mdi-close</v-icon>
              </v-btn>

              <v-btn
                color="green"
                @click="dialog = true, abrirFirma ( )"        
                class="mr-2"
                tile
                dark
              >
                Acepto
                <v-icon small right>mdi-check</v-icon>
              </v-btn>

              <!-- <v-btn
                text
                color="primary"
                @click="limpiar()"
                small
              >
                <span v-if="this.$vuetify.breakpoint.name == 'xs'">
                  <v-icon color="primary">mdi-eraser</v-icon>
                </span>
                <span v-else>
                  Limpiar
                </span>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card outlined class="mb-2">
        <v-card-title></v-card-title>
        <v-card-text class="black--text text-h6" v-if="aguinaldo">
          <b>Trabajador:</b> {{ getdatosUsuario.nombre_completo }}<br/>
          <b>Puesto:</b> {{ format( aguinaldo.puesto ) }}<br/>
          <b>Fecha ingreso:</b> {{ format( aguinaldo.fecha_ingreso ) }}<br/>
          <b>Bono Navideño:</b> {{ format( aguinaldo.aguinaldo ) }}<br/><br/>

          <br/>

          <v-divider></v-divider>
          <b>FAVOR DE FIRMAR AQUÍ</b>
          <div>
            <canvas id="signature-pad" class="signature-pad" width="780" height="250"></canvas>
          </div>

          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
          >
            Aviso Importante
            <br/>
            <br/>
            Al confirmar su conformidad, usted acepta el monto indicado anteriormente. Posteriormente, se le asignará una cita exclusivamente para llevar a cabo la firma del documento correspondiente.
            <br/>
            <br/>

            Le recomendamos revisar cuidadosamente la información proporcionada, ya que durante su visita a corporativo no se realizarán aclaraciones adicionales sobre el monto.
            <br/>
            <br/>

            Agradecemos su comprensión y quedamos a su disposición para cualquier otra consulta previa a su aceptación.
          </v-alert>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <div>
          <canvas id="signature-pad" class="signature-pad" width="780" height="250"></canvas>
        </div> -->
        <v-card-actions>
          <v-btn
            color="black"
            @click="dialog = false"        
            small
            tile
            dark
          >
            Cancelar
            <v-icon small right>mdi-cancel</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="savePNG( 1 )"        
            small
            class="mr-2"
            tile
            dark
          >
            Sí, acepto
            <v-icon small right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRechazo"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card outlined class="mb-2">
        <v-card-title>Rechazar mi bono Navideño</v-card-title>
        <v-card-text class="black--text">
          <label>Por favor, ingresar tu inconformidad a continuación.</label>
          <v-textarea
            label="motivo"
            v-model="motivo_rechazo"
            :rows="3"
            hide-details
            filled
            auto-grow
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            @click="dialogRechazo = false"        
            small
            tile
            dark
          >
            Cancelar
            <v-icon small right>mdi-cancel</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="savePNG( 2 )"        
            small
            class="mr-2"
            tile
            dark
          >
            Enviar
            <v-icon small right>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  import SignaturePad from 'signature_pad'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      signaturePad:null,

      descriptor: null,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,

      firma: false,
      aguinaldo: null,
      dialogRechazo: false,
      motivo_rechazo: '',

    }),

    created( ) {
      this.initialize()
    },

    mounted () {
      // let canvas = document.getElementById('signature-pad');
      // this.signaturePad = new SignaturePad(canvas);
    },

    methods:{

      abrirFirma( ){
        this.dialog = true

        setTimeout(() => {
          let canvas = document.getElementById('signature-pad');
          this.signaturePad = new SignaturePad(canvas);
        }, 2000);

      },

      ver (pdf) {
        this.viewPdf = pdf
        this.dialog = true
        this.verElPdf = 'https://escuelakpi.club/manuales/reglamento.pdf'
      },

      initialize ( ) {
        this.cargar = true

        this.$http.get('aguinaldo.firma/' + this.getdatosUsuario.id_trabajador).then(response=>{
          this.cargar       = false
          this.firma        = response.data.estatusFirma
          this.aguinaldo    = response.data.aguinaldo
        }).catch( error =>{ 
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })  
      },

      async savePNG ( estatus ){
        this.cargar = true

        let url = this.signaturePad.toDataURL();

        // Archivo blob binario
        let blobBin = atob(url.split(',')[1]);
        let array = [];
        // Recorremos el resultado
        for(let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }

        // Convertimos a bloc
        const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
        // Lo convertimos a archivo para poder agregarle una imagen
        const file2 = new File([file], this.folio+".png",{ type: "image/png" })

        // Creamos el documento
        let formData = new FormData();

        formData.append("file"            , file2);
        formData.append("nombre"          , this.getdatosUsuario.nombre_completo);
        formData.append("id_trabajador"   , this.getdatosUsuario.id_trabajador  );
        formData.append("aguinaldo"       , this.format( this.aguinaldo.aguinaldo ) );
        formData.append("puesto"          , this.aguinaldo.puesto );
        formData.append("fecha_ingreso"   , this.aguinaldo.fecha_ingreso );
        formData.append("aguinaldo2"      , this.aguinaldo.aguinaldo );
        formData.append("dias_aguinaldo"  , 0 );
        formData.append("sueldo_dia"      , 0 );
        formData.append("motivo_rechazo"  , this.motivo_rechazo );
        formData.append("firma"           , this.firma );
        formData.append("estatus"         , estatus );

        // const payload = {
        //   nombre          : this.getdatosUsuario.nombre_completo,
        //   id_trabajador   : this.getdatosUsuario.id_trabajador  ,
        //   aguinaldo       : this.format( this.aguinaldo.aguinaldo ) ,
        //   puesto          : this.aguinaldo.puesto ,
        //   fecha_ingreso   : this.aguinaldo.fecha_ingreso ,
        //   aguinaldo2      : this.aguinaldo.aguinaldo ,
        //   dias_aguinaldo  : 0 ,
        //   sueldo_dia      : 0 ,
        //   motivo_rechazo  : this.motivo_rechazo,
        //   firma           : this.firma,
        //   estatus,
        // }

        // agregamos el documento

        // Guardamos el documento
        this.$http.post('crear-firma-bono/' + this.getdatosUsuario.id_trabajador, formData).then(response=>{
          this.cargar = false
          // this.signaturePad.clear();
          this.dialog = false
          this.dialogRechazo = false
          this.motivo_rechazo = ''
          this.validarSuccess('Documento firmado correctamente')
          this.initialize( )
          // window.open(axios.defaults.baseURL + 'contratos/' + this.folio + '.pdf')
        }).catch(error=>{
          this.validarError(error.body.message)
        })
      },


      limpiar (){
        this.signaturePad.clear();
      },

      validarError (mensaje) {
        this.error       = mensaje
        this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
        this.successMensaje = mensaje
        this.dialogSuccess  = true
      },

      format( valor ){
        return valor.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN'
        });
      },
    },

    computed:{
      ...mapGetters( 'login' ,['getdatosUsuario']),

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
      },

      width(){
        return this.$vuetify.breakpoint.width
      },

      height(){
        return this.$vuetify.breakpoint.height - 250
      }
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>