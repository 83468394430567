<template>
	<v-card class="elevation-0" >
		<!-- Alumno nuevo -->
  	<v-alert
      border="bottom"
      type="info"
      text
      dark
    >
      Selecciona el prospecto por el cual está ligado el alumno, recuerda que el <b>FOLIO</b> de prospecto se da al momento de darle seguimiento, en dado caso de que no exista un prospecto, se puede generar desde aquí.
    </v-alert>
		<br/>

		<label><b>Aquí podrás revisar los datos de los hermanos agregados</b></label>


		<v-row>
			<v-col cols="12" md="6" lg="3">
				<v-text-field
		      v-model="cantidadHermanos"
			    append-outer-icon="mdi-plus-circle"
			    prepend-icon="mdi-minus-circle"
			    filled
			    label="Hermanos"
			    type="text"
			    readonly
			    @click:append-outer="addCantidad"
			    @click:prepend="menosCantidad"
			  ></v-text-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-expansion-panels>
					<v-expansion-panel
			      v-for="(item, i ) in hermanos"
			      :key="i"
			      class="elevation-10"
			    >
			      <v-expansion-panel-header>
			        Datos del hermano {{ i + 1 }}
			      </v-expansion-panel-header>
			      <v-expansion-panel-content>
					  	<!-- BUSCAR POR MATRICULA -->
					  	<label class="pl-0 black--text" v-if="i == 0">Prospecto</label>
							<v-row v-if="i == 0">
								<v-col cols="12" class="pt-0">
									<v-autocomplete
					          filled 
					          dense
					          clearable
					          v-model="id_prospecto"
					          :items="prospectos"
					          label="Selecciona el Prospecto"
					          hide-details
					          item-text="datos_completos"
					          item-value="idprospectos"
					          class="mt-4"
					        >
					        </v-autocomplete>
								</v-col>
							</v-row>

							<v-btn 
								v-if="!item.id_prospecto && i == 0"
								color="red" 
								dark 
								small 
								class="mt-2 text-capitalize" 
								@click="abrirDialogProspecto()"
								tile
							>
								No existe el prospecto
							</v-btn>

							<v-divider class="my-4"></v-divider>

					    <!-- Datos generales del alumno -->
					    <label class="pl-0 black--text" >Datos generales</label>
							<v-row v-if="item.id_prospecto">
								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.nombre"
									  label="Nombre"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.apellido_paterno"
									  label="Ap. Paterno"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.apellido_materno"
									  label="Ap. Materno"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.edad"
									  label="Edad"
									  type="number"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Datos del contacto -->
							<v-divider class="my-4"></v-divider>
							<label class="pl-0 black--text" >Datos de contacto</label>
							<v-row v-if="item.id_prospecto">
								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.telefono"
									  label="Teléfono"
									  type="number"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
									  filled 
									  dense
									  hide-details
									  v-model="item.celular"
									  label="Celular"
									  type="number"
									></v-text-field>
								</v-col>
							</v-row>
			      </v-expansion-panel-content>
			    </v-expansion-panel>
			  </v-expansion-panels>
			</v-col>
		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-card class="elevation-10">
			<v-card-title primary-title class="text-capitalize">
			  Datos del tutor
			</v-card-title>
		  <v-card-text>
		    <label class="pl-0 black--text">Datos generales</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.nombre"
						  label="Nombre"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.apellido_paterno"
						  label="Ap. Paterno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.apellido_materno"
						  label="Ap. Materno"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.tipo_parentesco"
						  label="Parentezco"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider class="my-4"></v-divider>
				<label class="pl-0 black--text">Datos de contacto</label>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.telefono"
						  label="Teléfono"
						  type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
						  filled
						  dense
						  hide-details
						  v-model="tutor.celular"
						  label="Celular"
						  type="number"
						></v-text-field>
					</v-col>
				</v-row>
		  </v-card-text>
		</v-card>

		<v-card-actions>
      <v-btn text @click="dialogAddAlumno.estatus = false">
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="steppers.paso = 2"
      >
        Continuar
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'grupo',
			'alumno',
			'steppers',
			'hermanos',
			'tutor',
			'dialogAddAlumno'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      cantidadHermanos: 2,

      respuestaAlerta:false,
      cargar: false,

			step:1,
			isTutor:true,

			opcion:1,

			posicionHermano: 0,

			empleados:[],
			sonHermanos:false,
			alumnoNuevo:{
				id_hermano: 0,
				nombre:'',
		    apellido_paterno:'',
		    apellido_materno:'',
		    telefono:'',
		    celular:'',
		    id_tipo_alumno:'1',
		    id_usuario_ultimo_cambio:'0',
		    matricula:'',
		    edad:'',
		    ocupacion:'Estudiante',
		    id_contacto:0,
		    unidad_negocio:0,
		    id_prospecto:null,
		    id_empleado: null,
		    tutor: null,
		    matricula_erp:'',
		    iderp:0,
			},

			existeMatricula: false,
    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},
    	existeProspecto:false,
    	prospecto: null,
    	prospectoSeleccionado: null,
    	alumnos:[],

    	alertaSeguridad: false,

    	headers:[
    		{ text: 'Nombre'       , value: 'nombre'       },
        { text: 'Telefono'     , value: 'telefono'     },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'ID prospecto' , value: 'id_prospecto' },
    	],

    	id_prospecto: null,



    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
    	prospectoSeleccionado( value){
    		if( value ){
    			this.alumnoNuevo.folio        = value.matricula
    			this.alumnoNuevo.nombre       = value.alumno
    			this.alumnoNuevo.telefono     = value.telefono
    			this.alumnoNuevo.celular      = value.telefono
    			this.alumnoNuevo.id_contacto  = value.idprospectos
    			this.alumnoNuevo.id_prospecto = value.idprospectos
    		}
    	},


    	id_prospecto( value ){
    		for( const i in this.hermanos ){
	    		this.hermanos[i].id_prospecto = value
	    	}
    	},

    	alumno( ){
  			this.alumnoNuevo = Object.assign({}, this.alumnoVacio)
  			this.tutor       = Object.assign({}, this.tutorVacio)

  			if( this.alumno && this.alumno.idprospectos ){
    			this.alumnoNuevo.nombre                   = this.alumno.alumno
		    	this.alumnoNuevo.apellido_paterno         = ''
		    	this.alumnoNuevo.apellido_materno         = ''
		    	this.alumnoNuevo.telefono                 = this.alumno.telefono
		    	this.alumnoNuevo.celular                  = this.alumno.telefono
		    	this.alumnoNuevo.id_tipo_alumno           = 1
		    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
		    	this.alumnoNuevo.unidad_negocio           = this.getEscuela
		    	this.alumnoNuevo.id_prospecto             = this.alumno.idprospectos
    		}
    	},
    },

    async created () {
    	this.hermanos
    	this.escuela = this.getEscuela

    	while( this.hermanos.length > 0  ){
    		this.hermanos.pop()
    	}

    	for( let i = 0 ; i < this.cantidadHermanos; i++  ){
	    	// Siempre debe empezar en 2!
	    	this.hermanos.push({
					nombre:'',
			    apellido_paterno:'',
			    apellido_materno:'',
			    telefono:'',
			    celular:'',
			    id_tipo_alumno:'1',
			    id_usuario_ultimo_cambio:this.getdatosUsuario.iderp,
			    matricula:'',
			    edad:'',
			    ocupacion:'Estudiante',
			    id_contacto:0,
			    unidad_negocio:this.getEscuela,
			    id_prospecto:null,
			    id_empleado: null,
			    tutor: null,
			    matricula_erp:'',
			    iderp:0,
			    grupo: null,
			    email: null
				})
    	}

    	await this.getProspectos()
    	await this.getAlumnos()
    },

    methods: {

    	addCantidad () {
        this.cantidadHermanos += 1
        this.hermanos.push({
					nombre:'',
			    apellido_paterno:'',
			    apellido_materno:'',
			    telefono:'',
			    celular:'',
			    id_tipo_alumno:'1',
			    id_usuario_ultimo_cambio:this.getdatosUsuario.iderp,
			    matricula:'',
			    edad:'',
			    ocupacion:'Estudiante',
			    id_contacto:0,
			    unidad_negocio:this.getEscuela,
			    id_prospecto:null,
			    id_empleado: null,
			    tutor: null,
			    matricula_erp:'',
			    iderp:0,
		    	grupo: null,
				})
      },

      menosCantidad () {
        if( this.cantidadHermanos > 2){ 
        	this.cantidadHermanos -= 1 
        	this.hermanos.pop()
        }
      },

    	getProspectos( ){
    		this.cargar    = true
      	this.prospectos = []

        return this.$http.get('inscripciones.prospectos').then(response=>{
        	this.prospectos = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      grabarDatos( ) {
      	this.cargar = true

      	if( !this.sonHermanos ){
	      	this.alumnoNuevo.tutor                    = this.isTutor ? this.tutor : null
	      	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	      	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

	        return this.$http.post('alumnos.add', this.alumnoNuevo).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	this.$emit('retornarAlumno', response.data )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		for( const i in this.hermanos ){
      			this.hermanos[i].unidad_negocio           = this.getEscuela
      			this.hermanos[i].id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
      		}
      		const payload = {
      			hermanos: this.hermanos,
      			tutor:    this.tutor
      		}

	        return this.$http.post('alumnos.add.hermanos', payload).then(response=>{
	        	this.alumnoNuevo = this.alumnoVacio
	        	this.tutor       = this.tutorVacio
	        	this.step        = 1
	        	let objectHermanos  = Object.assign({}, this.alumnoVacio)
	        	let arrayHermanos   = [objectHermanos]
	        	this.hermanos       = arrayHermanos
	        	this.$emit('retornarAlumno', response.data[0] )
	        	this.validarSuccess('Alumno registrado correctamente')
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}
      },

      abrirDialogProspecto( ){
      	this.existeProspecto              = true
      	this.parametrosProspectos.estatus = true
      },

      saveProspecto( value ){
        this.alumnoNuevo.nombre                   = value.nombre_completo
	    	this.alumnoNuevo.apellido_paterno         = ''
	    	this.alumnoNuevo.apellido_materno         = ''
	    	this.alumnoNuevo.telefono                 = value.telefono
	    	this.alumnoNuevo.celular                  = value.telefono
	    	this.alumnoNuevo.id_tipo_alumno           = 1
	    	this.alumnoNuevo.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp
	    	this.alumnoNuevo.unidad_negocio           = this.getEscuela
	    	this.alumnoNuevo.id_prospecto             = value.idprospectos
	    	this.existeMatricula                      = true

	    	for( const i in this.hermanos ){
	    		this.hermanos[i]['id_prospecto'] = value.idprospectos
	    	}

	    	this.getProspectos()

      },

      getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data

        	console.log( response.data[0] )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>
