<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
      <v-card-title primary-title class="text-subtitle-1">
        Precios 2025
      </v-card-title>
      <v-card-text class="black--text">

        <v-row v-if="[334,1,568,1932].includes( getdatosUsuario.iderp )">
          <v-col cols="12" lg="4" md="6">
            <label>Elige escuela</label>
            <v-radio-group v-model="escuela" row>
              <v-radio label="INBI" :value="1"></v-radio>
              <!-- <v-radio label="FAST" :value="2"></v-radio> -->
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-img :src="url" />
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      edad: null,
      modalidad: null,
      horario: null,
      hermanos: null,

      promociones: [],
      escuela: null,
      tipoDeCurso: null,

      tiposCurso: [
          { label: 'Intensivo - Regular'                      , value: 1 , escuela: 2, imagename: '1 presencial.jpg' },
          { label: 'Intensivo - Regular - Hermanos'           , value: 2 , escuela: 2, imagename: '3 presencial beca.jpg' },
          { label: 'Intensivo - Regular - Sabatino'           , value: 3 , escuela: 2, imagename: '5 sabatino presencial).jpg' },
          { label: 'Intensivo - Regular - Sabatino - Hermanos', value: 4 , escuela: 2, imagename: '7 sabatino presencial beca.jpg' },

          { label: 'Intensivo - Online'                       , value: 5 , escuela: 2, imagename: '2 online.jpg' },
          { label: 'Intensivo - Online - Hermanos'            , value: 6 , escuela: 2, imagename: '4 curso online beca.jpg' },
          { label: 'Intensivo - Online - Sabatino'            , value: 7 , escuela: 2, imagename: '6 sabatino online.jpg' },
          { label: 'Intensivo - Online - Sabatino - Hermanos' , value: 8 , escuela: 2, imagename: '8 sabatino online beca.jpg' },



          { label: 'Adultos - Regular'                      , value: 9 , escuela: 1, imagename: 'Adults.jpg' },
          { label: 'Adultos - Regular - Hermanos'           , value: 23, escuela: 1, imagename: 'Adults beca de hermanos.jpg' },
          { label: 'Adultos - Regular - Sabatino'           , value: 10, escuela: 1, imagename: 'Adults sabatino.jpg' },
          { label: 'Adultos - Regular - Sabatino - Hermanos', value: 11, escuela: 1, imagename: 'Adults sabatino beca de hermanos.jpg' },

          { label: 'Adultos - Online'                       , value: 12, escuela: 1, imagename: 'Online2.jpg' },
          { label: 'Adultos - Online - Hermanos'            , value: 12, escuela: 1, imagename: 'Online beca de hermanos2.jpg' },
          { label: 'Adultos - Online - Sabatino'            , value: 13, escuela: 1, imagename: 'Online sabatino2.jpg' },
          { label: 'Adultos - Online - Sabatino - Hermanos' , value: 14, escuela: 1, imagename: 'Online sabatino beca de hermanos2.jpg' },

          { label: 'Teens - Regular'                        , value: 15, escuela: 1, imagename: 'Teens.jpg' },
          { label: 'Teens - Regular - Hermanos'             , value: 24, escuela: 1, imagename: 'Teens beca de hermanos.jpg' },
          { label: 'Teens - Regular - Sabatino'             , value: 16, escuela: 1, imagename: 'Teens sabatino.jpg' },
          { label: 'Teens - Regular - Sabatino - Hermanos'  , value: 17, escuela: 1, imagename: 'Teens sabatino beca de hermanos.jpg' },


          { label: 'KIDS - Regular'                         , value: 21, escuela: 1, imagename: 'Kids.jpg' },
          { label: 'KIDS - Regular - Hermanos'              , value: 22, escuela: 1, imagename: 'Kids beca de hermanos.jpg' },




        ]

    }),

    created( ){
      this.escuela = this.getEscuela
      this.initialize( )
    },

    methods:{

      initialize( ){
        this.cargar = true;
        this.promociones = [];
        this.$http.get("promociones.imagenes").then((response) => {
          this.promociones = response.data
          this.cargar = false;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      }

    },

    computed:{
      ...mapGetters('login',['getEscuela','getdatosUsuario']),

      filteredTiposCurso() {
        // Filtrar los cursos según la escuela seleccionada
        return this.tiposCurso.filter(item => item.escuela === this.escuela);
      },

      url( ){

        let nameArchivo = this.escuela == 2 ? 'precios_2025_fast.jpeg' : 'precios_2025_inbi.jpeg'

        return 'https://escuelakpi.club/kpi/imagenes-promociones/' + nameArchivo

      }

    },

    watch: {
      // Cuando cambia la escuela, limpiar la selección del tipo de curso
      escuela() {
        this.tipoDeCurso = null;
      }
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}

</style>
