<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        x-small
        class="mr-2"
        title="Historial"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-history</v-icon>
      </v-btn>
    </template>

    <v-card>
      <!-- Header del modal - Punto de expansión: Se pueden añadir acciones adicionales en el header -->
      <v-card-title class="headline primary white--text">
        Historial del Servicio
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-row>
          <!-- Sección 1: Información básica del servicio
               Punto de expansión: Se pueden añadir más detalles del servicio -->
          <v-col cols="6">
            <v-card outlined>
              <v-card-text>
                <div class="text-h6 mb-2">Información del Servicio</div>
                <v-row>
                  <v-col cols="6">
                    <div class="caption grey--text">Servicio</div>
                    <div class="subtitle-1">{{ servicio.servicio }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="caption grey--text">Cuenta</div>
                    <div class="subtitle-1">{{ servicio.cuenta }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Sección 2: Estado de pagos
               Punto de expansión: Se puede añadir historial de pagos, gráficas de pagos, etc -->
          <v-col cols="6">
            <v-card outlined>
              <v-card-text>
                <div class="text-h6 mb-2">Estado de Pagos</div>
                <v-row>
                  <v-col cols="6">
                    <div class="caption grey--text">Última Fecha de Vencimiento</div>
                    <div class="subtitle-1">{{ servicio.fecha_vencimiento }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="caption grey--text">Próximo Pago</div>
                    <div class="subtitle-1">
                      {{ calcularProximoPago(servicio.fecha_vencimiento, servicio.recurrencia) }}
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-3">
                  <v-col cols="6">
                    <div class="caption grey--text">Recurrencia</div>
                    <div class="subtitle-1">
                      <v-chip small color="primary" text-color="white">
                        {{ getRecurrenciaText(servicio.recurrencia) }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="caption grey--text">Estado</div>
                      <div class="subtitle-1">
                        <v-chip
                          small
                          :color="estadoPago.color"
                          text-color="white"
                        >
                          {{ estadoPago.texto }}
                        </v-chip>
                      </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Sección 3: Recordatorios y alertas
               Punto de expansión: Se pueden añadir más tipos de alertas, notificaciones, etc -->
          <v-col cols="6">
            <v-card outlined>
              <v-card-text>
                <div class="text-h6 mb-2">Recordatorios</div>
                <v-alert
                  dense
                  :type="isVencido ? 'error' : 'info'"
                  border="left"
                >
                  {{ mensajeVencimiento }}
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-text>
                <div class="d-flex align-center mb-4">
                  <div class="text-h6">Historial de Pagos</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    small
                    @click="abrirDialogPago"
                  >
                    <v-icon left small>mdi-plus</v-icon>
                    Agregar Pago
                  </v-btn>
                </div>

                <!-- Tabla de historial de pagos -->
                <v-data-table
                  :headers="pagoHeaders"
                  :items="historialPagos"
                  :items-per-page="5"
                  class="elevation-1"
                  :loading="loadingPagos"
                  loading-text="Cargando... Por favor, espere"
                  no-data-text="No hay registros disponibles"
                >

                  <template v-slot:item.fecha_vencimiento="{ item }">
                    {{ item.fecha_vencimiento_formatted }}
                  </template>
                  <template v-slot:item.fecha_pago="{ item }">
                    {{ item.fecha_pago_formatted }}
                  </template>

                  <template v-slot:item.monto_solicitado="{ item }">
                    {{ formatCurrency(item.monto_solicitado) }}
                  </template>

                  <template v-slot:item.monto_pagado="{ item }">
                    {{ formatCurrency(item.monto_pagado) }}
                  </template>

                  <!-- Columna de evidencia -->
                  <template v-slot:item.evidencia="{ item }">
                    <v-btn
                      x-small
                      icon
                      color="info"
                      @click="mostrarEvidencia(item)"
                      v-if="item.evidencia"
                    >
                      <v-icon small>mdi-image</v-icon>
                    </v-btn>
                    <span v-else>Sin evidencia</span>
                  </template>

                  <!-- Columna de diferencia -->
                  <template v-slot:item.diferencia="{ item }">
                    <span
                      :class="{
                        'red--text': item.diferencia < 0,
                        'green--text': item.diferencia > 0,
                        'blue--text': item.diferencia === 0
                      }"
                    >
                      {{ formatCurrency(item.diferencia) }}
                      <span v-if="item.diferencia < 0"> (Saldo Pendiente)</span>
                      <span v-else-if="item.diferencia > 0"> (Saldo a Favor)</span>
                    </span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      x-small
                      icon
                      color="primary"
                      class="mr-2"
                      @click="editarPago(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      icon
                      color="error"
                      @click="confirmarBorrado(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Modal para agregar/editar pago -->
          <v-dialog v-model="dialogPago" max-width="500px">
            <v-card>
              <v-card-title class="headline primary white--text">
                {{ editedIndex === -1 ? 'Registrar Nuevo Pago' : 'Editar Pago' }}
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialogPago = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="pt-4">
                <v-form ref="formPago" v-model="validForm">
                  <v-row>
                    <!-- Fecha de Vencimiento - Solo visible al crear nuevo pago -->
                    <v-col cols="6" v-if="editedIndex === -1">
                      <v-menu
                        v-model="menuFechaVenc"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nuevoPago.fecha_vencimiento"
                            label="Fecha de Vencimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="nuevoPago.fecha_vencimiento"
                          @input="menuFechaVenc = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- Fecha de Pago - Siempre editable -->
                    <v-col :cols="editedIndex === -1 ? 6 : 12">
                      <v-menu
                        v-model="menuFechaPago"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nuevoPago.fecha_pago"
                            label="Fecha de Pago"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || 'Campo requerido']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="nuevoPago.fecha_pago"
                          @input="menuFechaPago = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- Monto Solicitado - Solo visible al crear nuevo pago -->
                    <!-- <v-col cols="6" v-if="editedIndex === -1">
                      <v-text-field
                        v-model.number="nuevoPago.monto_solicitado"
                        label="Monto Solicitado"
                        prefix="$"
                        type="number"
                        :rules="[v => !!v || 'Campo requerido']"
                      ></v-text-field>
                    </v-col> -->

                    <!-- Monto Pagado - Solo visible al crear nuevo pago -->
                    <v-col cols="6" v-if="editedIndex === -1">
                      <v-text-field
                        v-model.number="nuevoPago.monto_pagado"
                        label="Monto Pagado"
                        prefix="$"
                        type="number"
                        :rules="[v => !!v || 'Campo requerido']"
                      ></v-text-field>
                    </v-col>

                    <!-- Evidencia - Siempre editable -->
                    <v-col cols="12">
                      <v-file-input
                        v-model="nuevoPago.evidencia"
                        label="Evidencia"
                        accept="image/*"
                        prepend-icon="mdi-camera"
                        :show-size="true"
                        truncate-length="25"
                        :placeholder="nuevoPago.evidencia_actual || 'Seleccione un archivo'"
                      ></v-file-input>
                    </v-col>

                    <!-- ID Requisición - Editable -->
                    <v-col cols="12" >
                      <v-text-field
                        v-model="nuevoPago.id_requisicion"
                        label="ID Requisición"
                        type="number"
                        :rules="[v => !!v || 'Campo requerido']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="dialogPago = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  @click="guardarPago"
                  :disabled="!validForm"
                  :loading="guardando"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal para mostrar evidencia -->
          <v-dialog v-model="dialogEvidencia" max-width="1200px">
            <v-card>
              <v-card-title class="headline">
                Evidencia de Pago
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogEvidencia = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="text-center">
                <v-img
                  :src="evidenciaSeleccionada"
                  max-height="600"
                  contain
                ></v-img>
              </v-card-text>
            </v-card>
          </v-dialog>

           <!-- Diálogo de confirmación borrar -->
          <v-dialog v-model="dialogConfirmacion" max-width="400px">
            <v-card>
              <v-card-title class="headline">
                Confirmar Eliminación
              </v-card-title>
              <v-card-text>
                ¿Está seguro que desea eliminar este pago?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogConfirmacion = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="error"
                  @click="borrarPago"
                  :loading="borrando"
                >
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Punto de expansión: Nuevas secciones
               Aquí se pueden añadir más secciones como:
               - Historial detallado de pagos
               - Documentos relacionados
               - Gráficas y estadísticas
               - Comentarios o notas
               - Archivos adjuntos -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HistorialServicio',

  // Props - Punto de expansión: Se pueden añadir más props para nuevas funcionalidades
  props: {
    servicio: {
      type: Object,
      required: true
    }
  },

  // Data - Punto de expansión: Añadir nuevos estados para funcionalidades futuras
  data: () => ({
    dialog: false,
    url:'',
    dialogPago: false,
    dialogEvidencia: false,
    loadingPagos: false,
    guardando: false,
    validForm: false,
    menuFechaVenc: false,
    menuFechaPago: false,
    evidenciaSeleccionada: null,
    editedIndex: -1,
    editedItem: null,
    dialogConfirmacion: false,
    itemToDelete: null,
    borrando: false,
    historialPagos: [],
    pagoHeaders: [
      { text: 'Fecha Venc.', value: 'fecha_vencimiento' },
      { text: 'Fecha Pago', value: 'fecha_pago' },
      { text: 'Solicitado', value: 'monto_solicitado' },
      { text: 'Pagado', value: 'monto_pagado'},
      { text: 'Diferencia', value: 'diferencia' },
      { text: 'Evidencia', value: 'evidencia', sortable: false },
      { text: 'ID de Partida', value: 'id_requisicion' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    nuevoPago: {
      id_pago: null,
      fecha_vencimiento: '',
      fecha_pago: '',
      monto_solicitado: null,
      monto_pagado: null,
      evidencia: null,
      evidencia_actual: '',
      id_requisicion: null,
      id_servicio: null
    }
  }),

  // Computed - Punto de expansión: Añadir más propiedades computadas para nuevas funcionalidades
  computed: {
    isVencido() {
       // Busca el último pago esperado
       const ultimaFechaVencimiento = this.calcularProximoPago(
        this.historialPagos.length > 0
          ? this.historialPagos[0].fecha_vencimiento
          : this.servicio.fecha_vencimiento,
        this.servicio.recurrencia
      );

      // Verifica si existe pago para esta fecha
      const existePago = this.historialPagos.some(pago =>
        pago.fecha_vencimiento === ultimaFechaVencimiento && pago.monto_pagado > 0
      );

      return !existePago && new Date(ultimaFechaVencimiento) < new Date();
    },

    mensajeVencimiento() {
      if (this.isVencido) {
        return 'Pago pendiente vencido';
      }

      const ultimaFechaVencimiento = this.calcularProximoPago(
        this.historialPagos.length > 0
          ? this.historialPagos[0].fecha_vencimiento
          : this.servicio.fecha_vencimiento,
        this.servicio.recurrencia
      );

      const diasRestantes = Math.ceil(
        (new Date(ultimaFechaVencimiento) - new Date()) / (1000 * 60 * 60 * 24)
      );
      return `Próximo pago en ${diasRestantes} días`;
    },

    estadoPago() {
      const fechaActual = new Date();
      const fechaVencimiento = new Date(this.servicio.fecha_vencimiento);

      // Verificar si existe un pago para la fecha de vencimiento actual
      const pagoActual = this.historialPagos.find(pago =>
        new Date(pago.fecha_vencimiento).toDateString() === fechaVencimiento.toDateString()
      );

      if (pagoActual) {
        return {
          texto: 'Pagado',
          color: 'success'
        };
      }

      return fechaActual > fechaVencimiento
        ? { texto: 'Vencido', color: 'error' }
        : { texto: 'A tiempo', color: 'info' };
    }
  },

  created() {
    this.url = axios.defaults.baseURL + 'imagenes-servicios/'
  },

  methods: {

    formatFecha(fecha) {
      return new Date(fecha).toLocaleDateString('es-MX', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },

    abrirDialogPago() {
      this.dialogPago = true

      // Obtener última fecha de vencimiento
      let ultimaFecha = this.servicio.fecha_vencimiento;

      if (this.historialPagos.length > 0) {
      // Si hay pagos previos, usar la fecha de vencimiento del último pago
      ultimaFecha = this.historialPagos[0].fecha_vencimiento;
      }

      // Calcular siguiente fecha basada en recurrencia
      const fechaVencimiento = this.calcularProximoPago(ultimaFecha, this.servicio.recurrencia);


      // Resetear y preparar el nuevo pago
      this.nuevoPago = {
        fecha_vencimiento: fechaVencimiento,
        fecha_pago: '',
        monto_solicitado: null,
        monto_pagado: null,
        evidencia: null,
        id_requisicion: null,
        id_servicio: this.servicio.idservicios_sistemas  // Asignar el ID del servicio actual
      }
    },

    // Calcula la próxima fecha de pago basada en la recurrencia
    calcularProximoPago(fechaBase, recurrencia) {
      const fecha = new Date(fechaBase);

      // Convertir recurrencia a número
      const rec = parseInt(recurrencia);

      switch(rec) { // Usar el número convertido
        case 1: // Diario
          fecha.setDate(fecha.getDate() + 1);
          break;
        case 2: // Mensual
          fecha.setMonth(fecha.getMonth() + 1);
          break;
        case 3: // Bimestral
          fecha.setMonth(fecha.getMonth() + 2);
          break;
        case 4: // Anual
          fecha.setFullYear(fecha.getFullYear() + 1);
          break;
      }
      return fecha.toISOString().split('T')[0];
    },


    async cargarHistorialPagos() {
      this.loadingPagos = true
      try {
        // Aquí deberías hacer la llamada a tu API
        const response = await this.$http.get(`servicios_sistemas.list.pagos/${this.servicio.idservicios_sistemas}`)
        console.log('Datos recibidos:', response.data); // Para debug
        this.historialPagos = response.data.map(pago => ({
          ...pago,
          fecha_vencimiento_formatted: this.formatFecha(pago.fecha_vencimiento),
          fecha_pago_formatted: this.formatFecha(pago.fecha_pago),
          diferencia: pago.monto_pagado - pago.monto_solicitado
        }))
        .sort((a, b) => new Date(b.fecha_vencimiento) - new Date(a.fecha_vencimiento)); // Ordenar por fecha descendente
      } catch (error) {
        console.error('Error al cargar historial:', error)
        this.$emit('mostrar-error', 'Error al cargar el historial de pagos')
      } finally {
        this.loadingPagos = false
      }
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
      }).format(value)
    },


    mostrarEvidencia(item) {
      this.evidenciaSeleccionada = this.url + item.evidencia
      this.dialogEvidencia = true
    },

    async guardarPago() {
      if (!this.$refs.formPago.validate()) return

      this.guardando = true
      const formData = new FormData()

      // Agregar campos al FormData de manera más directa
      for (const [key, value] of Object.entries(this.nuevoPago)) {
        // Ignorar evidencia_actual y manejar evidencia especialmente
        if (key === 'evidencia_actual') continue;
        if (key === 'evidencia' && value) {
          formData.append('evidencia', value);
        } else if (value !== null) {
          formData.append(key, value);
        }
      }

      try {
        if (this.editedIndex > -1) {
          await this.$http.put(
            `servicios_sistemas.update.pago.erp/${this.nuevoPago.id_pago}`,
            formData
          )
          this.$emit('mostrar-exito', 'Pago actualizado exitosamente')
        } else {
          await this.$http.post('servicios_sistemas.add.pago.erp', formData)
          this.$emit('mostrar-exito', 'Pago registrado exitosamente')
        }

        this.dialogPago = false
        await this.cargarHistorialPagos()
        this.$refs.formPago.reset()
        this.editedIndex = -1
      } catch (error) {
        console.error('Error al guardar:', error)
        this.$emit('mostrar-error', 'Error al guardar el pago')
      } finally {
        this.guardando = false
      }
    },

    editarPago(item) {
      this.editedIndex = this.historialPagos.indexOf(item)
      this.nuevoPago = {
        // Solo copiamos los campos que queremos permitir editar
        id_pago: item.id_pago,
        fecha_pago: item.fecha_pago,
        evidencia: null,
        evidencia_actual: item.evidencia,
        // Mantener los campos que no se editarán pero son necesarios
        fecha_vencimiento: item.fecha_vencimiento,
        monto_solicitado: item.monto_solicitado,
        monto_pagado: item.monto_pagado,
        id_requisicion: item.id_requisicion,
        id_servicio: this.servicio.idservicios_sistemas
       }
      this.dialogPago = true
    },

    confirmarBorrado(item) {
      this.itemToDelete = item
      this.dialogConfirmacion = true
    },

    async borrarPago() {
      this.borrando = true;
      try {
        await this.$http.delete(`servicios_sistemas.delete.pago.erp/${this.itemToDelete.id_pago}`);
        this.$emit('mostrar-exito', 'Pago eliminado exitosamente');
        await this.cargarHistorialPagos();
      } catch (error) {
        console.error('Error al eliminar:', error);
        this.$emit('mostrar-error', 'Error al eliminar el pago');
      } finally {
        this.borrando = false;
        this.dialogConfirmacion = false;
        this.itemToDelete = null;
      }
    },

    resetForm() {
      this.$refs.formPago.reset()
      this.nuevoPago = {
        id_pago: null,
        fecha_vencimiento: '',
        fecha_pago: '',
        monto_solicitado: null,
        monto_pagado: null,
        evidencia: null,
        evidencia_actual: '', // Resetear también este campo
        id_requisicion: null,
        id_servicio: this.servicio.idservicios_sistemas
      }
    },

    // Obtiene el texto de recurrencia
    getRecurrenciaText(value) {
      const recurrencias = {
        1: "Diario",
        2: "Mensual",
        3: "Bimestral",
        4: "Anual"
      }
      return recurrencias[value] || ""
    },

    // Obtiene el texto del estado
    getEstatusTexto(estatus) {
      const estatusMap = {
        0: 'En proceso',
        1: 'Aprobado',
        2: 'Con evidencia',
        3: 'Rechazado'
      }
      return estatusMap[estatus]
    },

    // Obtiene el color del estado
    getEstatusColor(estatus) {
      const colorMap = {
        0: 'warning',
        1: 'success',
        2: 'info',
        3: 'error'
      }
      return colorMap[estatus]
    }



    // Puntos de expansión - Métodos futuros:
    // - cargarHistorialPagos()
    // - cargarDocumentos()
    // - agregarComentario()
    // - generarReporte()
    // - exportarHistorial()
    // - enviarNotificacion()
  },

  mounted() {
    this.cargarHistorialPagos()
  }

}
</script>
