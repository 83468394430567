<template>
  <v-container fluid class="mt-4 ml-2">
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Validar Ingresos
            <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>

            <v-btn
              class="mr-2"
              small dark color="green"
              @click="exportExcel( tableData  , 'VALIDAR_INGRESOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>
          </v-card-title>

          <!-- <v-btn
            color="orange"
            tile
            dark
            small
            @click="getIngresosRastreo()"
            class="mr-2"
          >
          	<v-icon small left>mdi-refresh</v-icon>
          	Actualizar
          </v-btn> -->

          <v-snackbar
            v-model="alerta.activo"
            :color="alerta.color"
            top
            right
          >
            <div class="pa-4">
              {{ alerta.texto }}
            </div>
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="alerta.activo = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>

          <v-row justify="end">
            <v-col cols="9" md="9" lg="9">
              <v-radio-group v-model="selectedSource" row>
                <v-radio
                  label="Seleccionar Fuente"
                  :value="null"
                ></v-radio>
                <v-radio
                  label="BBVA"
                  :value="'bbva'"
                ></v-radio>
                <v-radio
                  label="Tarjetas"
                  :value="'tarjetas'"
                ></v-radio>
                <v-radio
                  label="HSBC"
                  :value="'hsbc'"
                ></v-radio>
                <v-radio
                  label="WEB BR"
                  :value="'webbr'"
                ></v-radio>
                <!-- Puedes agregar más fuentes aquí fácilmente -->
              </v-radio-group>

              <v-radio-group v-model="selectedSchool" row>
                <v-radio
                  label="FAST"
                  :value="2"
                ></v-radio>
                <v-radio
                  label="INBI"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-col
            cols="12"
            class="pr-10 pl-10"
          >
            <v-card flat>
              <v-alert
                v-if="!selectedSource"
                outlined
                type="info"
                prominent
              >
                Por favor, seleccione una fecha y una fuente de datos antes de cargar un archivo.
              </v-alert>
              <v-alert outlined type="warning" prominent v-if="!tableData.length && !reportes.length">
                NO SE HA CARGADO NINGUN DOCUMENTO.
              </v-alert>

              <v-row justify="end">
              <!-- <v-col cols="12" md="10" lg="6">
                  <v-text-field
                    v-model="search"
                    dense
                    filled
                    label="Buscar"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model="fechaInicio"
                    label="Fecha de inicio"
                    type="date"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model="fechaFin"
                    label="Fecha de fin"
                    type="date"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-card v-if="tableData.length" >
                <v-data-table
                    dense
                    :headers="currentHeaders"
                    :items="tableData"
                    :search="search"
                    item-key="_uniqueKey"
                    :page.sync="page"
                    @page-count="pageCount = $event"
                    fixed-header
                    :height="tamanioPantalla"
                    locale="es"
                  >
                  <template v-slot:item.url_foto="{ item }">
                    <v-img
                      :src="'https://escuelakpi.club/kpi/comprobante-pago/' + item.url_foto"
                      contain
                      max-height="100"
                      aspect-ratio="2"
                      @click="openImageDialog(item.url_foto)"
                      class="clickable-image"
                    />
                  </template>

                  <template v-slot:item.repetidos="{ item }">
                    <v-chip
                      v-if="item.repetidos"
                      color="red lighten-5"
                    >
                      Repetido
                    </v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <template v-if="item.aceptado !== undefined && item.aceptado === 0">
                      <v-radio-group
                        v-model="item.aceptado"
                        row
                        @change="aceptarPago(item)"
                      >
                        <v-radio
                          label="Aceptar"
                          color="success"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="Rechazar"
                          color="error"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <template v-else-if="item.aceptado !== undefined && (item.aceptado === 1 || item.aceptado === 2)">
                      <v-chip
                        :color="item.aceptado === 1 ? 'success' : 'error'"
                      >
                        {{ item.aceptado === 1 ? 'Aceptado' : 'Rechazado' }}
                      </v-chip>
                    </template>
                  </template>
                </v-data-table>
                <v-dialog
                  v-model="imageDialog"
                  max-width="90%"
                  max-height="90%"
                >
                  <v-card>
                    <v-card-title class="text-h5">
                      Imagen
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="imageDialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-img
                        :src="fullImageSrc"
                        contain
                        max-height="70vh"
                      ></v-img>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-card>
              <div class="text-center pt-2" v-if="tableData.length">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros"/>
    <carga v-if="cargar"/>
  </v-container>
</template>

<script>
import axios from 'axios';
  import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    	UploadExcelComponent
    },

    mixins: [ validarErrores, funcionesExcel, ],

	  data() {
	    return {
	    	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

        selectedSource: null,
        fechaInicio: '',
        fechaFin: '',
        selectedSchool: 1,
        fullImageSrc: '',
        imageDialog: false,

        ingresos:[],

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,

	      search:'',


        // Configura tus fuentes de manera más escalable
        sourceConfigurations: {
          bbva: {
            headers: [
              { text: 'Día', value: 'dia' },
              { text: 'Concepto / Referencia', value: 'concepto' },
              { text: 'Abono', value: 'abono' },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Fecha de pago', value: 'fecha_pago' },
              { text: 'Evidencia'            , value: 'url_foto', sortable: false },
              { text: 'Repetidos', value: 'repetidos', sortable: true },
              { text: 'Actions', value: 'actions' }
              // ... otros headers de BBVA
            ],
            processData(value, me) {
              // Lógica específica para procesar datos de BBVA
              value.dia = me.excelDateToJSDate(value['Día'])
              value.concepto = value['Concepto / Referencia']
              value.abono = value['Abono']
              // Agrega más transformaciones según sea necesario
              return value
            },
            matchIncome(value, ingresos) {
              // Lógica para encontrar ingresos coincidentes para BBVA
              let primerSeparador = value.concepto.split('/')
              let segundoSeparador = primerSeparador[primerSeparador.length-1].trim().split(' ')
              let palabraBuscar = segundoSeparador[0]

              //Existe ingreso
              return ingresos.find(el => el.aut_clave_rastreo == palabraBuscar || el.folio_operacion == palabraBuscar)
            },
            valoresRepetidos(value, ingresos) {
            // Separar la referencia como se hace en matchIncome
            let primerSeparador = value.concepto.split('/')
            let segundoSeparador = primerSeparador[primerSeparador.length-1].trim().split(' ')
            let palabraBuscar = segundoSeparador[0]

            // Filtrar ingresos que coincidan con la referencia
            const result = ingresos.filter(el => el.aut_clave_rastreo == palabraBuscar || el.folio_operacion == palabraBuscar);

            return result.length;
            }
          },
          tarjetas: {
            headers: [
              { text: 'Número de comercio', value: 'num_comercio' },
              { text: 'Nombre de Comercio', value: 'nom_comercio' },
              { text: 'Fecha de archivo', value: 'fecha_archivo' },
              { text: 'Monto Original de la Transacción', value: 'monto_original' },
              { text: 'Tipo de transacción', value: 'tipo_transaccion' },
              { text: 'Número de tarjeta', value: 'num_tarjeta' },
              { text: 'ID de la transacción', value: 'id_transaccion' },
              { text: 'Código de Autorización', value: 'codigo_auth' },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Fecha de pago', value: 'fecha_pago' },
              { text: 'Evidencia'            , value: 'url_foto', sortable: false },
              { text: 'Repetidos', value: 'repetidos', sortable: true },
              { text: 'Actions', value: 'actions' }
              // ... otros headers de tarjetas
            ],
            processData(value, me) {
              // Eliminar comillas simples de los valores
              for (let key in value) {
                  if (typeof value[key] === 'string') {
                    value[key] = value[key].replace(/'/g, '')
                  }
                }
              // Lógica específica para procesar datos de tarjetas
              value.num_comercio = value['Número de comercio']
              value.nom_comercio = value['Nombre de Comercio']
              value.fecha_archivo = me.excelDateToJSDate(value['Fecha de archivo'])
              value.hora_archivo = value['Hora de archivo']
              value.monto_original = value['Monto Original de la Transacción']
              value.moneda_monto = value['Moneda del Monto Original de Transacción']
              value.tipo_transaccion = value['Tipo de transacción']
              value.metodo_pago = value['Método de Pago']
              value.num_tarjeta = value['Número de tarjeta']
              value.id_transaccion = value['ID de la transacción']
              value.codigo_auth = value['Código de Autorización']
              // Agrega más transformaciones
              return value
            },
            matchIncome(value, ingresos) {
              // Lógica para encontrar ingresos coincidentes para tarjetas

              // Filtrar por escuela si está seleccionada
              const filteredIngresos = this.selectedSchool
                ? ingresos.filter(el => el.escuela === this.selectedSchool)
                : ingresos;

              // Filtrar elementos que coincidan con la condición
              const result = filteredIngresos.filter(el => el.aut_clave_rastreo == value.codigo_auth);

              // Crear un array con los valores de monto_pagado
              const montos = result.map(registro => registro.monto_pagado);

              // Identificar montos repetidos
              const repetidos = montos.filter((monto, index, self) => self.indexOf(monto) !== index);

              // Marcar los objetos del array original cuyos monto_pagado están repetidos
              const conRepetidos = result.map(registro => ({
                ...registro,
                esRepetido: repetidos.includes(registro.monto_pagado)
              }));

              return conRepetidos.find(el => el.aut_clave_rastreo == value.codigo_auth || el.folio_operacion == value.codigo_auth);
            },
            valoresRepetidos(value, ingresos) {
              // Filtrar por escuela si está seleccionada
              const filteredIngresos = this.selectedSchool
                ? ingresos.filter(el => el.escuela === this.selectedSchool)
                : ingresos;

              // Filtrar elementos que coincidan con la condición
              const result = filteredIngresos.filter(el => el.aut_clave_rastreo == value.codigo_auth || el.folio_operacion == value.codigo_auth);


              return result.length
            }
          },
          hsbc: {
            headers: [
              { text: 'Nombre de cuenta'          , value: 'nombre_cuenta'    },
					    { text: 'Referencia bancaria'       , value: 'ref_banco'        },
					    { text: 'Referencia de cliente'     , value: 'ref_cliente'      },
					    { text: 'Tipo de TRN'               , value: 'trn'              },
              { text: 'Importe de crédito'        , value: 'importe_credito'  },
              { text: 'Fecha del apunte'          , value: 'fecha_apunte'     },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Fecha de pago', value: 'fecha_pago' },
              { text: 'Evidencia'            , value: 'url_foto', sortable: false },
              { text: 'Repetidos', value: 'repetidos', sortable: true },
              { text: 'Actions', value: 'actions' }
            ],
            processData(value, me) {
              value.nombre_cuenta = value['Nombre de cuenta']
	            value.ref_banco = value['Referencia bancaria']
              value.ref_cliente = value['Referencia de cliente']
              value.trn = value['Tipo de TRN']
              value.importe_credito = value['Importe de crédito']
              value.fecha_apunte = me.excelDateToJSDate( value['Fecha del apunte'] )
              return value
            },
            matchIncome(value, ingresos) {

              //Existe ingreso
              /* return ingresos.find(el => el.aut_clave_rastreo == palabraBuscar) */
            }
          },
          webbr: {
            headers: [
              { text: 'Referencia'                    , value: 'referencia'        },
					    { text: 'Afiliación'                    , value: 'afiliacion'        },
					    { text: 'Código de autorización'        , value: 'codigo_auth'       },
					    { text: 'Modo de entrada'               , value: 'modo_entrada'      },
              { text: 'Importe'                       , value: 'importe'           },
					    { text: 'Modo'                          , value: 'modo'              },
					    { text: 'Resultado'                     , value: 'resultado'         },
					    { text: 'Folio'                         , value: 'folio'             },
              { text: 'Ref Cliente 1'                 , value: 'ref_cliente_uno'   },
					    { text: 'Ref Cliente 2'                 , value: 'ref_cliente_dos'   },
					    { text: 'Fecha Transacción'             , value: 'fecha_transaccion' },
					    { text: 'Hora Transacción'              , value: 'hora_transaccion'  },
              { text: 'Banco Emisor'                  , value: 'banco_emisor'      },
					    { text: 'Marca Tarjeta'                 , value: 'marca_tarjeta'     },
					    { text: 'Producto Tarjeta'              , value: 'producto_tarjeta'  },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Fecha de pago', value: 'fecha_pago' },
              { text: 'Evidencia'            , value: 'url_foto', sortable: false },
              { text: 'Repetidos', value: 'repetidos', sortable: true },
              { text: 'Actions', value: 'actions' }
            ],
            processData(value, me) {
              value.referencia = value['Referencia']
	            value.afiliacion = value['Afiliación']
              value.codigo_auth = value['Código de autorización']
              value.modo_entrada = value['Modo de entrada']
              value.importe = value['Importe']
              value.modo = value['Modo']
              value.resultado = value['Resultado']
	            value.folio = value['Folio']
              value.ref_cliente_uno = value['Ref Cliente 1']
              value.ref_cliente_dos = value['Ref Cliente 2']
              value.fecha_transaccion = me.excelDateToJSDate( value['Fecha Transacción'] )
              value.hora_transaccion = value['Hora Transacción']
              value.banco_emisor = value['Banco Emisor']
              value.marca_tarjeta = value['Marca Tarjeta']
              value.producto_tarjeta = value['Producto Tarjeta']
              return value
            },
            matchIncome(value, ingresos) {
              /* let primerSeparador = value.concepto.split('/')
              let segundoSeparador = primerSeparador[primerSeparador.length-1].trim().split(' ')
              let palabraBuscar = segundoSeparador[0] */

              //Existe ingreso
              return ingresos.find(el => el.folio_operacion == value.folio || el.aut_clave_rastreo == value.folio)
            },
            valoresRepetidos(value, ingresos) {
              // Filtrar por escuela si está seleccionada
              const filteredIngresos = this.selectedSchool
                ? ingresos.filter(el => el.escuela === this.selectedSchool)
                : ingresos;
              // Lógica para encontrar ingresos coincidentes para tarjetas

              // Filtrar elementos que coincidan con la condición
              const result = filteredIngresos.filter(el => el.folio_operacion == value.folio || el.aut_clave_rastreo == value.folio);


              return result.length
            }
          },
          // Puedes agregar más fuentes aquí fácilmente
          // nuevaFuente: { ... }
        },


        tableData: [],  // DATOS A MOSTRAR
        reportes: [],  // TABLA REPORTES

        alerta: { activo: false, texto: '', color: 'error' },
        overlay: false,

        codigos: []
        }
    },

	  async created(){
      this.url = axios.defaults.baseURL
      this.initialize()
	  },

	  computed: {
    ...mapGetters('login', ['getdatosUsuario']),
    tamanioPantalla() {
      return this.$vuetify.breakpoint.height - 380
    },
    currentHeaders() {
      return this.selectedSource
        ? this.sourceConfigurations[this.selectedSource].headers
        : []
    },

    filterDateIngresos(){
      let data = this.ingresos

      if (this.fechaInicio && this.fechaFin) {
          data = data.filter(el => {
            const entregaDate = (el.fecha_pago.substr(0,10));
            const startDate = (this.fechaInicio);
            const endDate = (this.fechaFin);

            // Incluir fechas que estén dentro del rango (inclusive)
            return entregaDate >= startDate && entregaDate <= endDate;
          });
        }

        return data
    },

    },

    watch: {
    selectedSchool(newValue) {
      this.initialize(); // Reinicializar ingresos cuando cambia la escuela
    }
  },

	  methods: {

      initialize () {
      	this.cargar = true
        this.ingresos = []
        return this.$http.get('ingresos.rastreo.all').then(response=>{
           // Filtrar ingresos por escuela si se ha seleccionado
          this.ingresos = this.selectedSchool
            ? response.data.filter(ingreso => ingreso.escuela == this.selectedSchool)
            : response.data;

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      /* initialize () {
      	this.cargar = true
        this.getIngresosRastreo()
      },

      getIngresosRastreo(){
        this.ingresos = []
        return this.$http.get('ingresos.rastreo.all').then(response=>{
           // Filtrar ingresos por escuela si se ha seleccionado
          this.ingresos = this.selectedSchool
            ? response.data.filter(ingreso => ingreso.escuela == this.selectedSchool)
            : response.data;

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }, */


      openImageDialog(imagePath) {
        this.fullImageSrc = 'https://escuelakpi.club/kpi/comprobante-pago/' + imagePath
        this.imageDialog = true
      },

      aceptarPago(item){
        const payload = {
          id_ingreso: item.id_ingreso,
          aceptado: item.aceptado
        }

        this.$http.put('ingresos.rastreo.update/' + item.id_ingreso, payload ).then(response=>{
            this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

	    obtenerDatos(results) {
        if (!this.selectedSource) return

        const config = this.sourceConfigurations[this.selectedSource]

        const Temporal = results.map(value => {
          // Procesa el valor según la configuración de la fuente
          value = config.processData(value, this)

          // Busca ingresos coincidentes
          const existeIngreso = config.matchIncome(value, this.filterDateIngresos)

          // Asigna datos de ingreso
          value.id_ingreso = existeIngreso ? existeIngreso.id_ingreso : null
          value.alumno = existeIngreso ? existeIngreso.alumno : null
          value.monto_pagado = existeIngreso ? existeIngreso.monto_pagado : null
          value.url_foto = existeIngreso ? existeIngreso.url_foto : null
          value.aceptado = existeIngreso ? existeIngreso.aceptado : null



          value.repetidos = config.valoresRepetidos(value, this.filterDateIngresos) > 1 ? true : false



          value.id_alumno = 0
          return value
        })

        this.tableData = Temporal.map((value, index) => ({
        ...value,
        _uniqueKey: index  // Agregar una clave única
      }))

    },


      beforeUpload(file) {
      // Primero valida la fuente
      if (!this.selectedSource) {
        this.alerta = {
          activo: true,
          texto: 'Por favor, seleccione una fuente de datos antes de cargar un archivo.',
          color: 'info'
        };
        return false;
      }
      // Check if date range is selected
      if (!this.fechaInicio || !this.fechaFin) {
        this.alerta = {
          activo: true,
          texto: 'Por favor, seleccione primero un rango de fechas antes de cargar un archivo.',
          color: 'info'
        };
        return false;
      }

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.alerta = {
        activo: true,
        texto: 'Por favor, no cargue archivos mayores a 1MB.',
        color: 'warning'
      }

      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },


      excelDateToJSDate(serial) {
        if (serial) {
          // Eliminar cualquier espacio en blanco y convertir a cadena
          serial = String(serial).trim()

          // Intentar convertir directamente si es un número
          if (!isNaN(serial)) {
            // Método original para fechas numéricas de Excel
            var utc_days = Math.floor(serial - (25567 + 1));
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            const fecha = (new Date(date_info - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            return fecha;
          }

          // Intentar parsear diferentes formatos de fecha
          try {
            // Formatos comunes de fecha
            const formatosFecha = [
              /^\d{1,2}\/\d{1,2}\/\d{2,4}$/, // dd/mm/yyyy o mm/dd/yyyy
              /^\d{2,4}-\d{1,2}-\d{1,2}$/, // yyyy-mm-dd o dd-mm-yyyy
              /^\d{1,2}\.\d{1,2}\.\d{2,4}$/ // dd.mm.yyyy
            ];

            // Verificar si la fecha coincide con alguno de los formatos
            for (let formato of formatosFecha) {
              if (formato.test(serial)) {
                // Intentar crear una fecha con Date.parse()
                const fecha = new Date(serial);
                if (!isNaN(fecha)) {
                  return fecha.toISOString().substr(0, 10);
                }
              }
            }

            // Si no se puede parsear, devolver null
            return null;
          } catch (error) {
            console.error('Error al parsear fecha:', serial, error);
            return null;
          }
        }

        return null;
      },

       excelSerialToTime(serial) {
        if (serial) {
            /*
            Calcular la hora
            */
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            const hora = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);

            return hora;
        }

        return null;
        },

	    procesarInformacion( ){
	    	this.cargar = true
    		this.$http.post('conmutador.cargar.datos.entradas', this.tableData ).then(response=>{
    			this.tableData = []
    			this.validarSuccess( response.data.message )
    			this.cargar = false
    		}).catch(error=>{
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
	    },

	  }
	}
</script>

<style scoped>
  .clickable-image {
    cursor: pointer;
    transition: opacity 0.3s;
  }
  .clickable-image:hover {
    opacity: 0.7;
  }
</style>

