<template>
	<v-row justify="center" class="ma-0">
		<v-col xs="12" v-if="bitacora.length ===  0" >
			<v-alert type="info"> Tu bitacora está vacia. </v-alert>
		</v-col>

		<v-col xs="12" v-if="bitacora.length">
			<v-card outlined class="pa-3">
				<v-card-title primary-title class="py-0">
					<span><v-icon left>mdi-account</v-icon>Alumnos</span>
					<v-spacer></v-spacer> 
					<span class="text-right "><h3><strong>{{ totalArt }}</strong></h3></span>
				</v-card-title>
			</v-card>
		</v-col>

		<v-col cols="12" v-if="bitacora.length">
			<v-card  v-for="(item , i) in bitacora" :key="i" class="shadowCard mb-3">
				<v-card-text class="black--text">
					<b>Alumno:</b> {{ item.alumno }}<br/>
					<b>Matricula:</b> {{ item.matricula }}<br/>
					<b>Monto:</b> {{ item.monto }}<br/>
					<b>Tipo de pago:</b> {{ item.forma_pago }}<br/>
					<b>Comentarios:</b> {{ item.comentarios }}
				</v-card-text>
			</v-card>
		</v-col>

		<!-- Botón para pagar -->
		<v-footer absolute color="white" class="mb-2 px-2">
      <v-spacer></v-spacer>
      <v-btn dark block color="orange" @click="dialogConfirmacion = true"> 
				Agregar más alumnos
			</v-btn>
    </v-footer>

    <v-dialog
      v-model="dialogConfirmacion"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Agrear a la bitacora
        </v-card-title>
        <v-card-actions>
          <v-row>
						<v-col cols="12" class="pt-0">
							<v-autocomplete
			          filled 
			          dense
			          clearable
			          v-model="alumno"
			          :items="alumnos"
			          label="Selecciona el alumno"
			          item-text="datos_completos"
			          item-value="id_alumno"
			          class="mt-4"
					  	  hide-details
			        >
			        </v-autocomplete>
						</v-col>
						<v-col cols="12">
							<label><b>Selecciona la forma de pago</b></label>

							<v-radio-group
					      v-model="tipo_pago"
					      column
					    >
					      <v-radio
					      	v-for="(item, i) in tipos_pago"
					      	:key="i"
					        :label="item.tipo"
					        :value="item.id"
					      ></v-radio>
					    </v-radio-group>
					  </v-col>
					  <v-col cols="12">
					  	<v-text-field
					  	  label="ingresar el monto"
					  	  v-model="monto"
					  	  filled
					  	  dense
					  	  hide-details
					  	  type="number"
					  	></v-text-field>
					  </v-col>

					  <v-col cols="12">
					  	<v-textarea
					  	  label="ingresar el comentario"
					  	  v-model="comentarios"
					  	  filled
					  	  dense
					  	  hide-details
					  	  :rows="3"
					  	  auto-grow
					  	></v-textarea>
					  </v-col>
					</v-row>
        </v-card-actions>
        <v-card-actions>
          <v-btn 
          	color="black"
          	tile
          	dark
          	small
          	@click="dialogConfirmacion = false"
          >
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>
        	<v-spacer></v-spacer>
        	<v-btn 
          	color="green"
          	tile
          	dark
          	small
          	@click="save()"
          >
        		<v-icon small left>mdi-content-save</v-icon>
        		Guardar
        	</v-btn>
        </v-card-actions>			
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-row>	
</template>

<script>
import axios from 'axios';
	import {mapActions, mapGetters} from 'vuex'
  import metodos from '@/mixins/metodos.js';
  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import Vue from 'vue'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, metodos ],
    
    data(){
			return{
	      // Alertas
	      parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,
				// getCarrito: []
				url   : axios.defaults.baseURL + 'imagenes-tienda/' ,
	      photo : 'nophoto.png',
	      dialogConfirmacion: false,
	      habil_compra:false,
	      bitacora: [],
	      alumno: null,
				alumnos:[],
				tipo_pago: null,
				tipos_pago:[
	      	{ id: 1, tipo: 'EFECTIVO'},
	      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
	      	{ id: 4, tipo: 'TARJETA' },
	      ],
	      monto:null,
	      comentarios:'',
			}
		},

		computed: {
			...mapGetters('Carrito',['getCarrito']),
			...mapGetters('login',['getdatosUsuario']),

			totalArt () {
				let items = 0
				if(this.getCarrito.length > 0){
					for(const i in this.getCarrito){
	    			items += this.getCarrito[i].cantidad 
	    		}
	    		return items
				}else{
					return 0;
				}
			},
    },

    async created(){
    	await this.initialize( )
    	await this.getAlumnos( )
    },

    methods: {
      ...mapActions('Carrito',['CerrarCarrito','updateCarrito','limpiarCarrito']),

      initialize ( ){
      	this.cargar = true
        this.bitacora = []
        return this.$http.get('bitacora.pagos/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.bitacora = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	save(position){
    		this.cargar = true

    		const payload = {
					id_alumno:   this.alumno,
					id_usuario:  this.getdatosUsuario.iderp ,
					monto:       this.monto,
					tipo_pago:   this.tipo_pago,
					comentarios: this.comentarios  ,
    		}

        return this.$http.post('bitacora.pagos.add', payload ).then(response=>{
        	this.validarSuccess('Datos generados correctamente')
        	this.alumno = null
        	this.tipo_pago = null
        	this.comentarios = null
        	this.monto = null
        	this.cargar = false
        	this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	remove(position){
    		if( !this.habil_compra ){
    			this.validarErrorDirecto('El día de compras son los Martes :(')
    			return true
    		}
    		if(this.getCarrito[position].cantidad == 1){
    			this.getCarrito.splice(position,1)
    		}else{
    			this.getCarrito[position].cantidad -= 1
    		}
    		this.updateCarrito(this.getCarrito)
    	},

    	solicitarArticulos ( ){
    		if( !this.habil_compra ){
    			this.validarErrorDirecto('El día de compras son los Martes :(')
    			return true
    		}
    		this.dialogConfirmacion = false
    		const payload = {
    			idplantel: this.getdatosUsuario.id_plantel,
    			iderp    : this.getdatosUsuario.iderp,
    			articulos: this.getCarrito
    		}

    		this.cargar = true
        this.tickets = []
        this.$http.post('articulos.compra', payload).then(response=>{
        	this.limpiarCarrito()
          this.validarSuccess('Solicitud envíada')
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			}
    },
	};
</script>
