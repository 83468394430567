<template>
  <v-dialog
    v-model="dialogTwilio.estatus"
    max-width="1600"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Asignación de Twilio</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( diapositivas  , 'ASIGNACION_TWILIO')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="dialog = true"
          small
          tile
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="vendedoras"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="primary" 
					    		x-small
					    		@click="editItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn 
					    		color="error" 
					    		x-small
					    		@click="deleteItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>

					    </template>

              <template v-slot:item.nombresTwilios="{ item }">
                <span v-for="(tw, i) in item.nombresTwilios" @click="abrirTablaTwilio( item )" v-if="item.twilios.length > 0 ">
                  {{ tw }}<br/>
                </span>

                <v-btn color="primary" small @click="abrirTablaTwilio( item )" v-if="!item.twilios.length" >Agregar</v-btn>
              </template>

					    <template v-slot:item.estado="{ item }">
					    	<div v-if="item.estado == 1">INBI MTY</div>
					    	<div v-if="item.estado == 2">INBI CDMX</div>
					    	<div v-if="item.estado == 3">INBI GDL</div>
					    	<div v-if="item.estado == 4">FAST MTY</div>
					    </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="dialogTwilio.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idtwilio_vendedoras }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-col cols="12">
          	<v-autocomplete
		  			  label="Vendedoras"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_vendedora"
		  			  :items="usuarios"
		  			  item-text="nombre_completo"
		  			  item-value="id_usuario"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

      		<v-col cols="12">
          	<v-text-field
          	  label="Prioridad"
          	  id="id"
          	  type="number"
          	  filled
          	  dense
          	  v-model="editedItem.prioridad"
		  			  hide-details
          	></v-text-field>
      		</v-col>

      		<v-col cols="12">
          	<v-autocomplete
		  			  label="Plantel"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_plantel"
		  			  :items="planteles"
		  			  item-text="plantel"
		  			  item-value="id_plantel"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialog = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para editar los twilios -->
    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogTwilios"
      max-width="600px"
      persistent
    >
      <v-card v-if="twilios">
        <v-card-title>
          <span class="text-subtitle-1">Permisos de CRM</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>Vendedora: {{ twilios.nombre }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="10">
              <v-autocomplete
                label="Vendedoras"
                id="id"
                filled
                dense
                v-model="usuarioAreemplazar"
                :items="usuarios"
                item-text="nombre_completo"
                item-value="id_usuario"
                clearable
                return-object
                @keyup.enter="addDetalle( )"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn color="orange">
                <v-icon tile dark @click="addDetalle( )">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersTwilios"
            :items="twilios.twilios"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteReemplazo(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialogTwilios = false"
            tile
            dense
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogTwilio','escuela', 'nivel','leccion'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

      defaultItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

      twilios: null,

			search:'',
      vendedoras: [],
      usuarios: [],
      planteles:[],
      headers: [
        { text: 'ID'         , value: 'idtwilio_vendedoras'  },
        { text: 'Vendedora'  , value: 'nombre'               },
        { text: 'Plantel'    , value: 'plantel'              },
        { text: 'Zona'       , value: 'estado'               },
        { text: 'Prioridad'  , value: 'prioridad'            },
        { text: 'Iniciales'  , value: 'iniciales'            },
        { text: 'Match'      , value: 'comentarios'          },
        { text: 'Match'      , value: 'comentarios'          },
        { text: 'Twilios'    , value: 'nombresTwilios'       },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      headersTwilios: [
        { text: 'Vendedora'  , value: 'crm'                  },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      dialogTwilios: false,
      usuarioAreemplazar: null,

      contador : 0

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
      },

    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPlanteles()
    },

    methods: {


	    initialize() {
        this.cargar = true;
        this.vendedoras = []

        return axios.post('twilio.vendedoras').then(response => {
          this.vendedoras = response.data.vendedoras
          this.usuarios   = response.data.usuarios
          this.cargar = false;
        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },


      getPlanteles() {
        this.cargar = true;
        this.planteles = []

        return axios.get('planteles.list.erp').then(response => {
          this.planteles = response.data
          this.cargar = false;
        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },


      editItem (item) {
        this.editedIndex = this.vendedoras.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.vendedoras.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
      	this.$http.post('twilio.vendedoras.update', this.editedItem).then(response=>{
        	this.initialize()
      		this.close()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
        	
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        const buscarVendedora = this.usuarios.find( el => el.id_usuario == this.editedItem.id_vendedora )

        	if( !buscarVendedora ){

        		return this.validarErrorDirecto('Favor de seleccionar una vendedora')

        	}
        	// Aquí hay que generar los datos para enviar, como lo son.... 
        	const payload = {
        		idtwilio_vendedoras: this.editedItem.idtwilio_vendedoras,
        		id_vendedora       : this.editedItem.id_vendedora,
        		id_plantel         : this.editedItem.id_plantel,
        		prioridad          : this.editedItem.prioridad,
        		nombre             : buscarVendedora ? buscarVendedora.nombre_completo : 'Sin nombre',
        		deleted            : 0
        	}

	        // Lo mandapos por el EP
          this.$http.post('twilio.vendedoras.update', payload).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        } else {

        	const buscarVendedora = this.usuarios.find( el => el.id_usuario == this.editedItem.id_vendedora )

        	if( !buscarVendedora ){

        		return this.validarErrorDirecto('Favor de seleccionar una vendedora')

        	}
        	// Aquí hay que generar los datos para enviar, como lo son.... 
        	const payload = {
        		id_vendedora: this.editedItem.id_vendedora,
        		id_plantel  : this.editedItem.id_plantel,
        		prioridad   : this.editedItem.prioridad,
        		nombre      : buscarVendedora ? buscarVendedora.nombre_completo : 'Sin nombre'
        	}

	        // Lo mandapos por el EP
          this.$http.post('twilio.vendedoras.add', payload).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        }
      },


      abrirTablaTwilio( item ){

        this.twilios = item

        console.log( item )

        this.dialogTwilios = true
      
      },

      addDetalle( ){

        if( this.usuarioAreemplazar ){

          // Buscar ese usuario a reemplazar en otros usuarios
          const { id_usuario } = this.usuarioAreemplazar

          for( const i in this.vendedoras ){

            const { twilios, nombre } = this.vendedoras[i]

            for( const j in twilios ){

              const { usuario_crm } = twilios[j]

              if( usuario_crm == id_usuario ){

                if( this.contador == 0 ){
                  
                  this.contador = 1
                  return this.validarErrorDirecto(`La vendedora ${nombre} ya cuenta con ese acceso, si estás consciente de eso, solo cierra está ventana e intenta nuevamente!`)

                }
              }

            }
          }


          this.cargar = true

          const payload = {

            idusuario  : this.twilios.id_vendedora,
            usuario_crm: this.usuarioAreemplazar.id_usuario,

          }

          // Lo mandapos por el EP
          this.$http.post('twilio.acceso.crm', payload).then(response=>{
            this.dialogTwilios      = false
            this.usuarioAreemplazar = null
            this.twilios            = null
            this.cargar = false
            this.contador = 0
            this.initialize()
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })


        }

      },


      deleteReemplazo( item ){

        // Lo mandapos por el EP
        this.$http.post('twilio.acceso.crm.delete', item).then(response=>{
          this.dialogTwilios      = false
          this.usuarioAreemplazar = null
          this.twilios            = null
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>