<template>
	<div>
		<div>
			<span v-for="(mensaje, i) in filterMensajes" :key="i">
		    <div class="message text-only mb-8">
		      <div :class="`${ mensaje.fromMe == 0 ? 'noresponse' : 'response' }`">

		        <p 
		          class="text" 
		          v-if="mensaje.type == 'chat'"
		          style="white-space: pre-line"
		        >
		          <span v-if="alumno && alumno.tipoChat == 'grupo'" class="subnombrechat">{{ mensaje.notifyName }}</span>
		          <br v-if="alumno && alumno.tipoChat == 'grupo'"/>
		          {{ mensaje.body }}
		        </p>

		        <p 
		          class="text" 
		          v-if="mensaje.type == 'image'"
		          style="white-space: pre-line"
		          @click="verImagen( mensaje.fullFileName )"
		          type="button"
		        >
		          <img :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" alt="Red dot" width="300" />
		        </p>

		        <p 
		          class="text" 
		          v-if="mensaje.type == 'sticker'"
		          style="white-space: pre-line"
		        >
		          <img :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" alt="Red dot" width="150"/>
		        </p>

		        <p 
		          class="text" 
		          v-if="mensaje.type == 'document'"
		          style="white-space: pre-line"
		        >
		          <v-chip
		            v-if="mensaje.mimetype == 'application/pdf'"
		            color="#f1f3f4"
		            label
		            text-color="black"
		            @click="verPDF( mensaje.fullFileName )"
		          >
		            <v-icon left>
		              mdi-file-pdf-box
		            </v-icon>
		            {{ mensaje.mimetype }}
		          </v-chip>
		        </p>

		        <p 
		          class="text pa-0 pt-2 px-2 audio" 
		          v-if="mensaje.type == 'ptt' || mensaje.type == 'audio'"
		          style="white-space: pre-line"
		        >
		          <audio controls style="width: 100%;">
		            <source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName" :type="mensaje.mimetype" >
		          </audio>
		        </p>

		        <p 
		          class="text" 
		          v-if="mensaje.type == 'video'"
		          style="white-space: pre-line"
		        >
		          <video controls width="300" v-if="mensaje.isGif == 0">
		            <source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName">
		          </video>
		          
		          <video width="300" v-if="mensaje.isGif == 1" autoplay loop muted>
		            <source :src="mensaje.url_servidor + '/whatsapp-imagenes/' + mensaje.fullFileName">
		          </video>
		        </p>

		        <p 
		          v-if="mensaje.fromMe == 1"
		          :class="`${ mensaje.fromMe == 0 ? 'time' : 'response-time time'} black--text`"
		        >{{ mensaje.vendedora }}  <br/></p>

		        <p 
		          :class="`${ mensaje.fromMe == 0 ? 'time' : 'response-time time'} black--text`"
		        >{{ mensaje.fecha_creacion }}</p>

		      </div>
		    </div>

		  </span>
		</div>
	  
	  <!-- Sección para enviar un mensaje -->
	  <div id="sectionMessage">
	  	
			<v-btn color="red"     small @click="deleteAudio()" class="mr-2" v-if="audio" dark><v-icon>mdi-delete</v-icon></v-btn>

			<v-btn color="orange"  small @click="pauseAudio()"  class="mr-2" v-if="audio" dark><v-icon>mdi-pause</v-icon></v-btn>

			<v-btn color="blue"    small @click="playAudio()"   class="mr-2" v-if="audio" dark><v-icon>mdi-play</v-icon></v-btn>

			<v-btn color="success" small @click="stopAudio()"   class="mr-2" v-if="audio" dark><v-icon>mdi-send</v-icon></v-btn>

			
			<v-progress-linear
				v-if="audio && grabando"
				class="mt-2"
	      indeterminate
	      color="green"
	    ></v-progress-linear>

		  <v-textarea
		  	v-if="!audio"
		  	absolute
		    label="Escribe un mensaje aquí"
		    solo
		    rounded
		    hide-details
		    single-line
		    dense
		    auto-grow
		    rows="1"
		    v-model="message"
		    :append-outer-icon="message ? 'mdi-send' : 'mdi-microphone'"
		    prepend-icon="mdi-paperclip"
		    @click:append-outer="message ? sendMessage() : grabarAudio()"
		    @click:prepend="dialogArchivos = true"
		    @keyup.enter="sendMessage"
		  ></v-textarea>
	  </div>

	  <!-- Chip de asignación -->
	  <v-btn color="orange" tile absolute right class="mt-10" top dark v-if="asignacion && asignacion.vendedora">{{ asignacion.vendedora }}</v-btn>
	  
	  <!-- DIALGO PARA AGREGAR UN ARCHIVO -->
    <v-dialog
	    v-model="dialogArchivos"
	    persistent
	    :max-width="400"
	  >
	  	<v-card class="mx-auto shadowCard" >
		    <v-card-title class="text-h6 font-weight-regular justify-space-between">
		      <span class="text-subtitle-1"> Agregar archivo </span>
		    </v-card-title>
		    <v-card-text align="center">
		      <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                <div class="dropZone-upload-limit-info">
                  <div>Tamaño máximo: 40 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>
         
          <div v-else>
          	<v-card
			      	width="300"
			        color="primary"
			        dark
			      >
			        <v-card-text  class="text-subtitle-1 text-center">
			          Archivo cargado
			        </v-card-text>
			      </v-card>

			      <v-card
			      	class="mt-2"
			      	width="300"
			      >
			        <v-textarea
						    label="Escribe un mensaje aquí"
						    filled
						    rounded
						    hide-details
						    dense
						    auto-grow
						    rows="1"
						    v-model="message"
						  ></v-textarea>
			      </v-card>

          </div>
		    </v-card-text>
		    <v-card-actions>
		      <v-btn color="red" dark small rounded @click="cancelar()">Cancelar</v-btn>
		      <v-spacer></v-spacer>
		      <v-btn color="success" dark small rounded @click="sendMessage()">Agregar</v-btn>
		    </v-card-actions>
		  </v-card>
		</v-dialog>

		<!-- Dialog para ver imágenes -->
		<v-dialog v-model="dialgImagen" max-width="600px" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined v-if="wha">
              <v-img :src="wha.url_servidor + '/whatsapp-imagenes/' + imagen" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src="wha.url_servidor + '/whatsapp-imagenes/' + imagen" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialgImagen = false"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px" persistent>
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="wha.url_servidor + '/whatsapp-imagenes/' + pdf" width="650" height="700" type="application/pdf" v-if="wha">
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</div>
</template>

<script>
import axios from 'axios';

  import lamejs from 'lamejs';

  import RecordRTC from 'recordrtc';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import AgregarArchivo        from '@/components/whatsapp/AgregarArchivo.vue'

  import validarErrores        from '@/mixins/validarErrores'


	export default {

		components:{
      Alerta,
      carga,
      AgregarArchivo
    },

    mixins: [ validarErrores ],

		props:[
			'mensajes',
			'alumno',
			'wha',
			'chat',
			'verTodosLosMensajes',
			'asignacion'
		],

		data: () => ({
      message: '',
      dialogArchivos: false,
      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,
      dialgImagen:false,
			url: 'https://escuelakpi.club/kpi/whatsapp-imagenes/',
			fullscreen: false,
			pdf:null,
			dialogPDF: false,
			
			imagen: null,
			grabando: false,
			mediaRecorder: null,
			chunks: [],
			audio: false,
			recording: false,
      blob: null,
      mp3Data: [],
      audioContext: null,
      audioInput: null,
      scriptProcessor: null,
      mp3Encoder: null,
      recorder: null,
      buffer: null,
      audioPath:null,

		}),

		computed:{
			filterMensajes(){

        let mensajes = this.mensajes

        if( !this.verTodosLosMensajes )
          mensajes = this.mensajes.filter( el => { return el.tos == this.wha.whatsappservidor || el.froms == this.wha.whatsappservidor })        

        return mensajes
      },
		},

		methods: {
			initialize () {
				this.cargar = true
				this.usuarios = []
				return this.$http.get('whatsapp.usuarios').then(response=>{
					this.usuarios = response.data
					this.cargar      = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			sendMessage( value ){
				this.cargar = true

        if( !this.wha ){ return this.validarErrorDirecto('Favor de seleccionar un Usuario') }

        this.cargar = true
        this.chats  = []

      	const url = this.wha.url_servidor + 'whatsapp.mensaje'

      	var formData = new FormData();
	      formData.append("froms"          , this.wha.whatsappservidor );
	      formData.append("_serialized"    , this.chat.id._serialized );
	      formData.append("imagen"         , this.file ? '1' : '0' );
	      formData.append("mensaje"        , this.message );
	      formData.append("audio"          , this.audio ? '1' : '0' );
	      formData.append("rutaAudio"      , this.audioPath );

	      if( this.file )
        	formData.append("file", this.file );

        return this.$http.post( url, formData ).then( async (response )=>{

      		// Limpiar dialogo de archivos
      		this.cancelar()

				  setTimeout(function(){document.getElementById('mensajesChat').scrollTop=document.getElementById('mensajesChat').scrollHeight},500);
		      
		      if( this.recorder ){
					  this.recorder.reset()
					  this.recorder.clearRecordedData()
			      console.log( this.recorder.getState() )
			      this.recorder.destroy()


			      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

						// Detener la transmisión del flujo de audio del micrófono
						stream.getTracks().forEach(track => track.stop());

						// Destruir la instancia del objeto MediaStream
						stream.getTracks().forEach(track => track.enabled = false);
						stream.getTracks().forEach(track => track.readyState == 'ended');

						console.log( stream )

		      }

					this.message         = ''
					this.limpiar()

          this.cargar  = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			},

      cancelar( ){
      	this.vistaPrevia        = null
	      this.archivos           = null
	      this.file               = '';
      	this.dialogArchivos     = false
      },

      getBase64(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = function () {
	        me.vistaPrevia = {
	          url: reader.result, 
	          image_name: file.name
	        }
	      };
	    },
   
			onChange(e) {
	      var files = e.target.files || e.dataTransfer.files;
	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile(files[0]);
	    },

	    createFile(file) {
	      if (file.size > 40000000) {
	        alert('please check file size no over 40 MB.')
	        this.dragging = false;
	        return;
	      }
	      
	      this.file = file;
	      this.getBase64(this.file)
	      this.dragging = false;
	    },

	    verImagen( value ){
        this.imagen      = value
        this.dialgImagen = true
      },

      verPDF( value ){
        this.pdf         = value
        this.dialogPDF   = true
      },

      grabarAudio( ){

      	// Pedimos el permiso para grabar
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

          this.recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/mpeg',
            recorderType: RecordRTC.StereoAudioRecorder,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1
          });
          
	      	this.grabando  = true
	      	this.audio     = true
          this.recording = true

          this.recorder.startRecording();
        })
        .catch(error => {
        	this.grabando = false
      		this.audio    = false
          console.error("No se pudo acceder al micrófono:", error);
  				this.validarErrorDirecto("Por favor, permite el acceso al micrófono para grabar.");
        });
      },

      async stopAudio(){
	      this.recording = false;
	      this.recorder.stopRecording( async () => {
	        this.blob = this.recorder.getBlob();

	        // Una vez grabado el blob, es necesario enviarlo al servidor para que lo convierta a MP3
	        this.audioPath = await this.saveAudioConverter( this.blob ).then( response => response )

	        console.log( this.audioPath )

	        this.audio = true
	        this.file  = true

	        this.sendMessage()
	        
	      });

      },

      saveAudioConverter() {
      	return new Promise((resolve, reject ) => {

      		// Crear la ruta de axios
	      	const url = this.wha.url_servidor + 'audio.parse'

	      	// Indicarle que es un dato blob y que enviara el archivo y lo convierta
		      axios.post(url, this.blob, { headers: { 'Content-Type': 'audio/mpeg' } } ).then(response => {
		      	
		      	// Si todo bien, retornamos la información
	          resolve( response.data )
	        }).catch(error => {

	        	// Si no, regresamos el error
	          reject( error )
	        });
      	})

	    },

	    deleteAudio(){
	    	this.recorder.reset()
			  this.recorder.clearRecordedData()
	      this.recorder.destroy()
	      this.limpiar()
	    },

	    pauseAudio(){
	    	this.grabando = false
	    	this.recorder.pauseRecording()
	    },

	    playAudio(){
	    	this.grabando = true
				this.recorder.resumeRecording()
	    },

	    limpiar(){
	    	this.audio           = false
				this.grabando        = false
				this.imagen          = null
				this.mediaRecorder   = null
				this.chunks          = []
				this.recording       = false
	      this.blob            = null
	      this.mp3Data         = []
	      this.audioContext    = null
	      this.audioInput      = null
	      this.scriptProcessor = null
	      this.mp3Encoder      = null
	      this.recorder        = undefined
	      this.buffer          = null
	      this.audioPath       = null
	    }

		},
	}
</script>
<style  scoped>

	#sectionMessage{
		position: absolute;
	  top: 670px;
  	left: 0px;
  	width: 100%;
  	background-color: #e9edef;
  	padding: 10px;
	}

	#sectionAsignacion{
		position: absolute;
	  top: 40px;
  	left: 80%;
  	width: 100%;
  	padding: 10px;
	}

	#preguntas::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
		width: 4px !important;
	}

	#preguntas::-webkit-scrollbar:vertical{
		width: 4px !important;
	}

	#preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
		display: none;
	}

	#preguntas::-webkit-scrollbar:horizontal{
		display: none;
	}

	#preguntas::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}

	/**********************************/

	#mensajesChat::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
		width: 4px !important;
	}

	#mensajesChat::-webkit-scrollbar:vertical{
		width: 4px !important;
	}

	#mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
		display: none;
	}

	#mensajesChat::-webkit-scrollbar:horizontal{
		display: none;
	}

	#mensajesChat::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}

	.container {
		padding:0;
		background-color: #FFF; 
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		height: 750px;
		margin-top: 25px;
	}

	/* ===== MENU ===== */
	.menu {
		float: left;
		height: 700px;;
		width: 70px;
		background: #4768b5;
		background: -webkit-linear-gradient(#4768b5, #35488e);
		background: -o-linear-gradient(#4768b5, #35488e);
		background: -moz-linear-gradient(#4768b5, #35488e);
		background: linear-gradient(#4768b5, #35488e);
		box-shadow: 0 10px 20px rgba(0,0,0,0.19);
	}

	.menu .items {
		list-style:none;
		margin: auto;
		padding: 0;
	}

	.menu .items .item {
		height: 70px;
		border-bottom: 1px solid #6780cc;
		display:flex;
		justify-content: center;
		align-items: center;
		color: #9fb5ef;
		font-size: 17pt;
	}

	.menu .items .item-active {
		background-color:#5172c3;
		color: #FFF;
	}

	.menu .items .item:hover {
		cursor: pointer;
		background-color: #4f6ebd;
		color: #cfe5ff;
	}

	/* === CONVERSATIONS === */

	.discussions {
		width: 100%;
		height: 702px;
		box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
		overflow: hidden;
		display: inline-block;
	}

	.discussions .discussion {
		width: 100%;
		height: 90px;
		background-color: #FAFAFA;
		display:flex;
		align-items: center;
		cursor: pointer;
	}

	.discussions .search {
		display:flex;
		align-items: center;
		justify-content: center;
		color: #E0E0E0;
	}

	.discussions .search .searchbar {
		height: 40px;
		background-color: #FFF;
		width: 70%;
		padding: 0 20px;
		border-radius: 50px;
		border: 1px solid #EEEEEE;
		display:flex;
		align-items: center;
		cursor: pointer;
	}

	.discussions .search .searchbar input {
		margin-left: 15px;
		height:38px;
		width:100%;
		border:none;
		font-family: 'Montserrat', sans-serif;;
	}

	.discussions .search .searchbar *::-webkit-input-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *:-moz-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *::-moz-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *:-ms-input-placeholder {
		color: #E0E0E0;
	}

	.discussions .message-active {
		height: 90px;
		background-color: #FFF;
		border-right: solid 5px #E91E63;
	}

	.discussions .discussion .photo {
		margin-left:20px;
		display: block;
		width: 45px;
		height: 45px;
		background: #E6E7ED;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.online {
		position: relative;
		top: 30px;
		left: 35px;
		width: 13px;
		height: 13px;
		background-color: #8BC34A;
		border-radius: 13px;
		border: 3px solid #FAFAFA;
	}

	.desc-contact {
		height: 43px;
		width:50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.discussions .discussion .name {
		margin: 0 0 0 20px;
		font-family:'Montserrat', sans-serif;
		font-size: 11pt;
		color:#515151;
	}

	.discussions .discussion .message {
		margin: 6px 0 0 20px;
		font-family:'Montserrat', sans-serif;
		font-size: 9pt;
		color:#515151;
	}

	.timer {
		margin-left: 15%;
		font-family:'Open Sans', sans-serif;
		font-size: 11px;
		padding: 3px 8px;
		color: #BBB;
		background-color: #FFF;
		border: 1px solid #E5E5E5;
		border-radius: 15px;
	}

	.chat {
		width: calc(100%);
	}

	.chat .messages-chat {
		padding: 25px 10px;
	}

	.chat .messages-chat .message {
		display:flex;
		align-items: center;
		margin-bottom: 2px;
	}

	.chat .messages-chat .message .photo {
		display: block;
		width: 45px;
		height: 45px;
		background: #E6E7ED;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.chat .messages-chat .text {
		margin: 0 35px;
		background-color: #fff;
		padding: 15px;
		border-radius: 12px;
	}


	.text-only {
		white-space: pre-line;
	}

	.time {
		font-size: 12px;
		color:lightgrey;
		margin-bottom:10px;
		margin-left: 38px;
	}

	.response-time {
		float: right;
		margin-right: 40px !important;
	}

	.response {
		float: right;
		margin-right: 0px !important;
		margin-left: auto; /* flexbox alignment rule */
		max-width: 90%;
	}

	.noresponse {
		max-width: 90%;
	}

	.response .text {
		background-color: #c5e6c1 !important;
		color: black;
	}

	.audio {
		background-color: #f1f3f4 !important;
	}

	.response .audio {
		background-color: #f1f3f4 !important;
	}

	.footer-chat {
		width: calc(65% - 66px);
		height: 80px;
		display:flex;
		align-items: center;
		position:absolute;
		bottom: 0;
		background-color: transparent;
		border-top: 2px solid #EEE;

	}

	.chat .footer-chat .icon {
		margin-left: 30px;
		color:#C0C0C0;
		font-size: 14pt;
	}

	.chat .footer-chat .send {
		color:#fff;
		background-color: #4f6ebd;
		position: absolute;
		right: 50px;
		padding: 12px 12px 12px 12px;
		border-radius: 50px;
		font-size: 14pt;
	}

	.chat .footer-chat .name {
		margin: 0 0 0 20px;
		text-transform: uppercase;
		font-family:'Montserrat', sans-serif;
		font-size: 13pt;
		color:#515151;
	}

	.chat .footer-chat .right {
		position: absolute;
		right: 40px;
	}

	.dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

</style>

