<template>
	<div>
		<v-card>
      <v-card-title>
        <v-card width="80" class="elevation-0 transparent">
          <v-img 
          	v-if="escuela == 2"
            src="@/assets/logo_fast.png"
          ></v-img>

          <v-img 
          	v-else
            src="@/assets/logo_inbi.png"
          ></v-img>

        </v-card>
        <span class="text-subtitle-1">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="editedItem.nombre_completo"
          label="Nombre completo"
          filled
          dense
          autofocus
        ></v-text-field>

        <v-autocomplete
          filled
          dense
          clearable
          v-model="editedItem.idmedio_contacto"
          :items="medios_contacto"
          label="Selecciona una campaña"
          persistent-hint
          item-value="idmedio_contacto"
          item-text="medio"
        >
        </v-autocomplete>

        <!-- <v-checkbox v-model="editedItem.respondio" label="Respondió?" @keyup="respondio(item)"></v-checkbox> -->
        <v-checkbox v-model="editedItem.sin_plantel" label="Sin plantel" @change="sinPlantel()"></v-checkbox>

        <v-autocomplete
          v-if="escuela == 1"
          :items="[
            {id:1,      nom: 'ANAHUAC'             , zona: 1},
            {id:2,      nom: 'Miguel Aleman'       , zona: 1},
            {id:3,      nom: 'Pablo Livas'         , zona: 1},
            {id:4,      nom: 'San Miguel'          , zona: 1},
            {id:5,      nom: 'Casa blanca'         , zona: 1},
            {id:6,      nom: 'Fresnos'             , zona: 1},
            {id:17,     nom: 'INBI ONLINE'         , zona: 1},
            {id:21,     nom: 'APODACA CENTRO'      , zona: 1},
            {id:22,     nom: 'LINCOLN'             , zona: 1},
            {id:23,     nom: 'ESCOBEDO'            , zona: 1},
            {id:24,     nom: 'SANTA CATARINA'      , zona: 1},
            {id:25,     nom: 'MONTEVIDEO'          , zona: 2},
            {id:27,     nom: 'ARAGON'              , zona: 2},
            {id:28,     nom: 'MISTERIOS'           , zona: 2},
            {id:29,     nom: 'TRANSITO'            , zona: 2},
            {id:31,     nom: 'CUITLAHUAC'          , zona: 2},
            {id:32,     nom: 'APODACA MM'          , zona: 1},
            {id: 10006, nom: 'TOREO'               , zona: 1},
            {id: 10007, nom: 'JUAREZ'              , zona: 1},
            {id: 10008, nom: 'CHAPULTEPEC M'       , zona: 1},
            {id: 10012, nom: 'COPILCO'             , zona: 2},
            {id: 10013, nom: 'COYOACAN'            , zona: 2},
            {id: 10014, nom: 'BARRANCA DEL MUERTO' , zona: 2},
            {id: 10002, nom: 'EL ROSARIO'          , zona: 3},
            {id: 10003, nom: 'BALBUENA'            , zona: 3},
            {id: 10009, nom: 'CHAPULTEPEC G'       , zona: 3},
            {id: 10010, nom: 'CRUZ DEL SUR'        , zona: 3},
            {id: 10011, nom: 'LAS FUENTES'         , zona: 3},
            {id: 10004, nom: 'REVOLUCION'          , zona: 3},
          ]"
          v-model="editedItem.id_sucursal_interes"
          item-text="nom"
          item-value="id"
          label="Sucursal de intéres"
          filled
          dense
          :readonly="editedItem.sin_plantel ? true : false"
        ></v-autocomplete>

        <v-autocomplete
          v-else
          :items="[
            {id:7, nom: 'FAST_APODACA', zona: 4},
            {id:8, nom:'FAST_NORIA'   , zona: 4},
            {id:9, nom:'FAST_RG'      , zona: 4},
            {id:10,nom:'FAST_LV'      , zona: 4},
            {id:11,nom:'FAST_ELOY'    , zona: 4},
            {id:12,nom:'FAST_MTR'     , zona: 4},
            {id:14,nom:'FAST_UNI'     , zona: 4},
            {id:16,nom:'FAST ONLINE'  , zona: 4}
          ]"
          v-model="editedItem.id_sucursal_interes"
          item-text="nom"
          item-value="id"
          label="Sucursal de intéres"
          filled
          dense
        ></v-autocomplete>

            <!-- <v-col cols="12" class="pb-0">
              <v-switch label="Foraneo" v-model="editedItem.foraneo" hide-details></v-switch>
            </v-col> -->

        <v-subheader class="mb-0 pb-0 mt-6">Información de contacto</v-subheader>
        <v-text-field
          v-model="editedItem.telefono"
          label="Teléfono"
          filled
          type="number"
          dense
          @keyup="validarTelefono( )"
          @keyup.enter="buscarTelefono( editedItem.telefono )"
        ></v-text-field>

        <v-select
          :items="vendedoras_plantel"
          v-model="editedItem.sucursal"
          return-object
          item-text="nombre_plantel"
          item-value="nombre_plantel"
          label="Sucursal"
          filled
          dense
        ></v-select>

        <v-row>
          <v-col cols="12" md="6">
            <label>Horario de interés</label>
            <v-radio-group v-model="editedItem.horario_interes">
              <v-radio
                label="Mañana"
                value="Mañana"
              ></v-radio>
              <v-radio
                label="Tarde"
                value="Tarde"
              ></v-radio>
              <v-radio
                label="Noche"
                value="Noche"
              ></v-radio>
              <v-radio
                label="Fin de semana"
                value="Fin de semana"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="6">
            <label>Curso de interés</label>
            <v-radio-group v-model="editedItem.curso_interes">
              <v-radio
                v-for="(curso, i) in cursos_escuela"
                :key="i"
                :label="curso.curso"
                :value="curso.idcursos_escuela"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-textarea
          v-model="editedItem.nota_inicial"
          filled
          dense
          rows="3"
          label="Nota inicial"
        > 
        </v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="black"
          dark
          @click="close"
          tile
          small
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          tile
          small
          @click="save"
        >
          <v-icon left small>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
	</div>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'formTitle',
			'editedItem',
			'cursos_escuela',
			'vendedoras_plantel',
			'fuentes',
			'detallesFilter',
			'medios_contacto',
			'campaniasFilter',
			'escuela',
      'contadorVendedoras'
	  ],

    whatch:{

    },

	  methods:{
		  save( ){
		  	this.$emit('save', this.editedItem )
		  },

		  close( ){
		  	this.$emit('close', this.editedItem )
		  },

		  buscarTelefono( telefono ){
		  	this.$emit('buscarTelefono', telefono )
		  },


      sinPlantel( ){

        if( this.editedItem.sin_plantel ){

          this.editedItem.id_sucursal_interes = 0


          let vendedoras = this.contadorVendedoras
          // Buscar la vendedora que mejor se acomode
          // Ordenar por % de convencimiento (desc) y luego por carga laboral (asc)
          vendedoras = vendedoras.sort((a, b) =>  a.ranking - b.ranking );

          let iderp =  vendedoras[0].iderp

          const existeVendedora = this.vendedoras_plantel.find( el => el.iderp == iderp )

          if( existeVendedora ){
            this.editedItem.sucursal = existeVendedora
          }

        }

      },

      validarTelefono( ){

        let planteles = [
          {id:1,      nom: 'ANAHUAC'       , zona: 1, escuela: 1},
          {id:2,      nom: 'Miguel Aleman' , zona: 1, escuela: 1},
          {id:3,      nom: 'Pablo Livas'   , zona: 1, escuela: 1},
          {id:4,      nom: 'San Miguel'    , zona: 1, escuela: 1},
          {id:5,      nom: 'Casa blanca'   , zona: 1, escuela: 1},
          {id:6,      nom: 'Fresnos'       , zona: 1, escuela: 1},
          {id:17,     nom: 'INBI ONLINE'   , zona: 1, escuela: 1},
          {id:21,     nom: 'APODACA CENTRO', zona: 1, escuela: 1},
          {id:22,     nom: 'LINCOLN'       , zona: 1, escuela: 1},
          {id:23,     nom: 'ESCOBEDO'      , zona: 1, escuela: 1},
          {id:24,     nom: 'SANTA CATARINA', zona: 1, escuela: 1},
          {id:25,     nom: 'MONTEVIDEO'    , zona: 2, escuela: 1},
          {id:27,     nom: 'ARAGON'        , zona: 2, escuela: 1},
          {id:28,     nom: 'MISTERIOS'     , zona: 2, escuela: 1},
          {id:29,     nom: 'TRANSITO'      , zona: 3, escuela: 1},
          {id:31,     nom: 'CUITLAHUAC'    , zona: 2, escuela: 1},
          {id:32,     nom: 'APODACA MM'    , zona: 1, escuela: 1},
          {id: 10002, nom: 'EL ROSARIO'    , zona: 2, escuela: 1},
          {id: 10003, nom: 'BALBUENA'      , zona: 2, escuela: 1},
          {id: 10004, nom: 'REVOLUCION'    , zona: 3, escuela: 1},
          {id:7, nom: 'FAST_APODACA'       , zona: 4, escuela: 2},
          {id:8, nom:'FAST_NORIA'          , zona: 4, escuela: 2},
          {id:9, nom:'FAST_RG'             , zona: 4, escuela: 2},
          {id:10,nom:'FAST_LV'             , zona: 4, escuela: 2},
          {id:11,nom:'FAST_ELOY'           , zona: 4, escuela: 2},
          {id:12,nom:'FAST_MTR'            , zona: 4, escuela: 2},
          {id:14,nom:'FAST_UNI'            , zona: 4, escuela: 2},
          {id:16,nom:'FAST ONLINE'         , zona: 4, escuela: 2}
        ]

        planteles = planteles.filter( el => el.escuela == this.escuela )

        // Validamos el teléfono
        if( this.editedItem.telefono ){

          let iniciales = this.editedItem.telefono.substr( 0, 2 )


          let zona = 0 
          
          // Eligió sin plantel, hay que poner a la vendedora nosotros mismos
          if( this.editedItem.sin_plantel ){

            // Sacamos los teléfonos de zona 3
            if( parseInt( iniciales ) >= 30 && parseInt( iniciales ) <= 40 ){

              zona = 3

            }

            // Ahora zona 2
            if( parseInt( iniciales ) >= 50 && parseInt( iniciales ) <= 60 ){

              zona = 2

            }

            if( zona == 0  ){

              zona = 1

            }

            // Ahora sacamos los planteles posibles
            planteles = planteles.filter( el => el.zona == zona )

            let sucursalesPosibles = planteles.map((registro => { return registro.id }))

            console.log( this.vendedoras_plantel )

            let posiblesVendedoras = this.vendedoras_plantel.filter( el => ![568].includes( el.iderp ) ).filter( el => sucursalesPosibles.includes( el.idplantel ) )
            console.log( posiblesVendedoras )

            for( const i in posiblesVendedoras ){

              const { iderp } = posiblesVendedoras[i]

              const existeVendedora = this.contadorVendedoras.find( el => el.iderp == iderp )

              posiblesVendedoras[i]['contactos'] = existeVendedora ? existeVendedora.contactos : 0 


            }


            posiblesVendedoras = posiblesVendedoras.sort((a, b) => parseFloat(a.contactos) - parseFloat(b.contactos) );

            this.editedItem.sucursal = posiblesVendedoras[0]
          }else{


            if( this.editedItem.id_sucursal_interes ){
              console.log('d')

              this.editedItem.sucursal = this.vendedoras_plantel.find( el => el.idplantel == this.editedItem.id_sucursal_interes )

            }

          }

        }

      }

	  }
	}
</script>