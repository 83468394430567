<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogResaginar.estatus"
    persistent
    max-width="1200"
  >
    <v-card>
    	<v-card-title class="text-subtitle-1">
    	  Reasignar prospectos de manera masiva v1
    	  <v-spacer></v-spacer>
    	  <v-icon @click="dialogResaginar.estatus = false">mdi-close</v-icon>
    	</v-card-title>
    	<v-card-text>
    		<v-row>
    			<v-col cols="12" md="8">
		    		<v-autocomplete
		          filled
		          dense
		          clearable
		          v-model="vendedora"
		          :items="vendedoras"
		          label="Selecciona vendedora"
		          hide-details
		          item-text="nombre_completo"
		          item-value="id_ciclo"
		          return-object
		        >
		        </v-autocomplete>
    			</v-col>

    			<v-col cols="12">
    				<v-radio-group
				      v-model="estatusProspecto"
				      row
				    >
				      <v-radio
				        label="Todos"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Cerrados"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="Abiertos"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Inscritos"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>


        <v-data-table
			    :headers="headers"
			    :items="filterProspectos"
			    dense
			    class="elevation-0 mt-4"
			    item-key="idprospectos"
			    show-select
			    v-model="prospectosAReasignar"
			  >
	    		<template v-slot:item.idetapa="{ item }">
						<v-chip x-small v-if="item.idetapa == 1" dark color="red">Atrasado</v-chip>
            <v-chip x-small v-if="item.idetapa == 2" dark color="green">Nuevo</v-chip>
            <v-chip x-small v-if="item.idetapa == 3" dark color="pink">Inducción</v-chip>
            <v-chip x-small v-if="item.idetapa == 4" dark color="orange">Con info</v-chip>
            <v-chip x-small v-if="item.idetapa == 5" dark color="brown">Sin info</v-chip>
            <v-chip x-small v-if="item.idetapa == 7" dark color="purple darken-4">2do Contacto</v-chip>
	        </template>

	        <template v-slot:item.finalizo="{ item }">
						<v-chip 
							x-small 
							dark 
							:color="item.finalizo == 1 ? 'dark' : 'green'"
						>
							{{ item.finalizo == 1 ? 'cerrado' : 'Abierto' }}
						</v-chip>
	        </template>

	        <template v-slot:item.idalumno="{ item }">
						<v-chip 
							x-small 
							dark 
							:color="!item.idalumno ? 'dark' : 'green'"
						>
							{{ !item.idalumno ? 'No' : 'Inscrito' }}
						</v-chip>
	        </template>

			  </v-data-table>

    	</v-card-text>

    	<v-card-actions>
		    <v-btn dark block @click="dialogAlerta = true" color="primary">REASIGNARRR</v-btn>
    	</v-card-actions>
    </v-card>


    <v-dialog
    	v-model="dialogAlerta"
    	max-width="400"
    >	
    	<v-card>
    		<v-card-title></v-card-title>
    		<v-card-text class="black--text text-center" >
    			Selecciona una opción para la reasignación
    			<br/>

    			<v-radio-group
			      v-model="opReasigancion"
			      row
			    >
			      <v-radio
			        label="Por zona"
			        :value="1"
			      ></v-radio>
			      <v-radio
			        label="Vendedora"
			        :value="2"
			      ></v-radio>
			    </v-radio-group>

			    <v-autocomplete
	          filled
	          dense
	          clearable
	          v-model="vendedoraUnica"
	          :items="vendedoras"
	          label="Selecciona vendedora"
	          hide-details
	          item-text="nombre_completo"
	          item-value="id_ciclo"
	          return-object
	          v-if="opReasigancion == 2"
	        >
	        </v-autocomplete>
    		</v-card-text>
    		<v-card-actions>
    			<v-btn color="black" dark small @click="dialogAlerta = false">No, cancelar</v-btn>
    			<v-spacer></v-spacer>
    			<v-btn color="green" dark small @click="iniciarReasignacion( )" v-if="opReasigancion">Sí, seguro</v-btn>
    		</v-card-actions>
    	</v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-dialog>
</template>


<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'


	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'


	export default {
		props:[
			'dialogResaginar'
	  ],

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      headers:  [
			  { text: 'Folio',    value: 'folio'           },
			  { text: 'Nombre',   value: 'nombre_completo' },
			  { text: 'Teléfono', value: 'telefono'        },
			  { text: 'Etapa',    value: 'idetapa'         },
			  { text: 'Fecha',    value: 'fecha_creacion'  },
			  { text: 'Finalizo', value: 'finalizo'        },
			  { text: 'Inscrito', value: 'idalumno'        },
			],
      vendedoras: [],
      vendedora : null,
      prospectos:[],
      prospectosAReasignar: [],

      estatusProspecto: 1,
      dialogAlerta: false,
      opReasigancion: null,
      vendedoraUnica: null


    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

      filterProspectos( ){

      	let prospectos = this.prospectos 
      	if( this.estatusProspecto != 1 ){

      		// Filtramos prospectos cerrados
      		if( this.estatusProspecto == 2 ){ return prospectos.filter( el => el.finalizo == 1 ) }
      		
      		// Filtramos prospectos Abiertos
      		if( this.estatusProspecto == 3 ){ return prospectos.filter( el => el.finalizo == 0 ) }
      		
      		// Filtramos prospectos inscritos
      		if( this.estatusProspecto == 4 ){ return prospectos.filter( el => el.idalumno ) }

      	}

      	return this.prospectos
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      vendedora( value ){

      	// Verificamos que el valor este lleno
      	if( value ){

      		// Desestrcuturamos el valor y sacamos el usuario
      		const { id_usuario } = value

      		// mandamos a llamar a la consulta
      		this.getProspectosDetalle( id_usuario )

      	}

      }
    },

    async created () {
      await this.getVendedorasCRM()
    },

    methods: {
    	getVendedorasCRM () {
      	this.cargar = true
        this.vendedoras = []
        return this.$http.get('vendedoras.list.crm').then(response=>{
        	this.vendedoras = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getProspectosDetalle ( id_usuario ) {
      	this.cargar     = true // iniciar el load
        this.prospectos = []   // Limpiamos prospectos

        return this.$http.get('prospectos.detalle/' + id_usuario ).then(response=>{
        	
        	this.prospectos = response.data // capturamos la respuesta
        	this.cargar      = false        // Cerramos el loader
        
        }).catch( error =>{
        	// Lanzamos el error 
          this.validarError( error.response.data.message )
        
        }).finally( () => { this.cargar = false })
      },

      iniciarReasignacion(){

      	// Mandamos los datos completos
      	const payload = {
      		vendedora            : this.vendedora,
					prospectosAReasignar : this.prospectosAReasignar,
					opReasigancion       : this.opReasigancion,
					vendedoraUnica       : this.vendedoraUnica
      	}

      	this.cargar = true
        
        return this.$http.post('reasignar.prospectos.masivo', payload ).then(response=>{

        	// Validamos que haya una vendedora seleccionada
        	if( this.vendedora ){

	      		// Desestrcuturamos el valor y sacamos el usuario
	      		const { id_usuario } = this.vendedora

	      		// mandamos a llamar a la consulta
	      		this.getProspectosDetalle( id_usuario )

	      	}

	      	this.prospectosAReasignar = []
	      	this.vendedoraUnica       = null
	      	this.opReasigancion       = null

        	this.validarSuccess('Datos reasignados correctamente')
        	this.dialogAlerta = false
        	this.cargar       = false
        	this.getVendedorasCRM( )
	      	// Agregarmos primero los datos
	      	
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }
    },
  }
</script>