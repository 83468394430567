<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
      <v-card-title primary-title class="text-subtitle-1">
        Plan de estudios 2025
      </v-card-title>
      <v-card-text class="black--text">

        <v-row v-if="[334,1,568,1932].includes( getdatosUsuario.iderp )">
          <v-col cols="12" lg="4" md="6">
            <label>Elige escuela</label>
            <v-radio-group v-model="escuela" row>
              <v-radio label="INBI" :value="1"></v-radio>
              <v-radio label="FAST" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4" md="6">

            <v-select
              v-model="tipoDeCurso"
              :items="filteredTiposCurso"
              item-text="label"
              item-value="value"
              label="Selecciona un tipo de curso"
              outlined
              return-object
              :disabled="!escuela"
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" v-if="tipoDeCurso">
          	<v-card>
		        	<v-card-title primary-title>
			          <v-spacer></v-spacer>
			          <v-btn
			            color="blue-grey"
			            class="ma-2 white--text"
			            @click="downloadImage(`https://escuelakpi.club/imagenes-promociones/${tipoDeCurso.imagename}`)"
			          >
			            Descargar
			            <v-icon
			              right
			              dark
			            >
			              mdi-cloud-download
			            </v-icon>
			          </v-btn>
			        </v-card-title>
			        <v-card-text>
		            <embed 
		            	v-if="tipoDeCurso"
			            :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-promociones/${tipoDeCurso.imagename}`" 
			            width="100%" 
			            :height="tamanioPantalla" 
			          />
			        </v-card-text>
          	</v-card>

          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      edad: null,
      modalidad: null,
      horario: null,
      hermanos: null,

      promociones: [],
      escuela: null,
      tipoDeCurso: null,

      url: '',
      tiposCurso: [
          { label: 'FAST INTENSIVO'       , value: 1 , escuela: 2, imagename: 'PDF FAST-INTENSIVO.pdf' },
          { label: 'FAST ONLINE SABATINO' , value: 2 , escuela: 2, imagename: 'PDF FAST-ONLINE SABATINO.pdf' },
          { label: 'FAST ONLINE'          , value: 3 , escuela: 2, imagename: 'PDF FAST-ONLINE.pdf' },
          { label: 'FAST SABATINO'        , value: 4 , escuela: 2, imagename: 'PDF FAST-SABATINO.pdf' },
          

          { label: 'INBI ADULTS ONLINE'           , value: 9 , escuela: 1, imagename: 'PDF - INBI ADULTS ONLINE.pdf' },
          { label: 'INBI ADULTS ONLINE SABATINO'  , value: 23, escuela: 1, imagename: 'PDF - INBI ADULTS ONLINE SABATINO.pdf' },
          { label: 'INBI ADULTS'                  , value: 10, escuela: 1, imagename: 'PDF - INBI ADULTS.pdf' },
          { label: 'INBI ADULTS SABATINO'         , value: 12, escuela: 1, imagename: 'PDF - INBI ADULTS SABATINO.pdf' },
          { label: 'INBI KIDS'                    , value: 13, escuela: 1, imagename: 'PDF - INBI KIDS.pdf' },
          { label: 'INBI TEENS SABATINO'          , value: 14, escuela: 1, imagename: 'PDF - INBI TEENS SABATINO.pdf' },
          { label: 'INBI TEENS'                   , value: 15, escuela: 1, imagename: 'PDF - INBI TEENS.pdf' },
          { label: 'PREVENTA'                     , value: 16, escuela: 1, imagename: 'preventa editable.pdf' },

          
        ]

    }),

    created( ){
      this.escuela = this.getEscuela
      this.url = 'https://escuelakpi.club/kpi/imagenes-promociones/'
      this.initialize( )
    },

    methods:{

      initialize( ){
        this.cargar = true;
        this.promociones = [];
        this.$http.get("promociones.imagenes").then((response) => {
          this.promociones = response.data
          this.cargar = false;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      async downloadImage( archivo ) {

        
        const downloadUrl = archivo;

        window.open(archivo, "_blank");

      },

    },

    computed:{
      ...mapGetters('login',['getEscuela','getdatosUsuario']),

      filteredTiposCurso() {
        // Filtrar los cursos según la escuela seleccionada
        return this.tiposCurso.filter(item => item.escuela === this.escuela);
      },

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
    	},

    },

    watch: {
      // Cuando cambia la escuela, limpiar la selección del tipo de curso
      escuela() {
        this.tipoDeCurso = null;
      }
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}

</style>