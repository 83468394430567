import Vue from 'vue'
import Vuex from 'vuex'

import router      from '@/router'

import login       from '@/modules/login'
import productos   from '@/modules/productos'
import tema        from '@/modules/tema'
import Carrito     from '@/modules/carrito';
import twilio      from '@/modules/twilio';

import createPersistedState  from  'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    abrir_carrito: false,
  },

  mutations: {
    ABRIR_CARRO(state, estado){
      state.abrir_carrito = estado
    }
  },

  actions: {
    abrirCarrito({commit}, estado){
      commit('ABRIR_CARRO',estado)
    },
  },

  getters:{
    getStateCarrito(state){
      return state.abrir_carrito
    }
  },

	modules:{
		login,
    productos,
    tema,
    Carrito,
    twilio
	},

  plugins: [createPersistedState()]

})
