<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Calculo de aguinaldos</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel( filterTrabajadores  , 'aguinaldos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>          
            <v-row >

              <v-col cols="12" md="3" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="puesto_seleccionado"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" lg="3">
                <v-autocomplete
                  :items="estatusAll"
                  v-model="estatus"
                  label="estatus"
                  clearable
                  filled
                  dense
                  item-text="estatus"
                  item-value="idestatus"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="5">
                <v-checkbox 
                  label="Sin teacher" 
                  v-model="sinTeacher"
                  hide-details
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="black--text text-h6">
                <b>TOTAL:  {{ this.format( total ) }}</b> 
              </v-col>
              <v-col cols="12" class="black--text">
                <b>Total:     {{  empleadosTotal  }}</b> <br/>
                <b>Firmados:  {{  firmados  }}</b> <br/>
                <b>Faltan:    {{  faltan  }}</b> <br/>
                <b>Avance:    %{{  avance  }}</b> <br/>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="grey"
                  tile
                  dark
                  @click="download( )"
                  small
                  v-if="[28,334,966,1,954].includes( getdatosUsuario.iderp )"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>

              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTrabajadores"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                  v-model="selected"
                  item-key="nombre_completo"
                  show-select
                >

                  <template v-slot:item.sueldo_mensual="{ item }">
                    {{ item.sueldo_mensual ? format( item.sueldo_mensual ) : 'NA' }}
                  </template>

                  <template v-slot:item.sueldo_semanal="{ item }">
                    {{ item.sueldo_semanal ? format( item.sueldo_semanal ) : 'NA' }}
                  </template>

                  <template v-slot:item.sueldo_dia="{ item }">
                    {{ item.sueldo_dia ? format( item.sueldo_dia ) : 'NA' }}
                  </template>

                  <template v-slot:item.aguinaldo="{ item }">
                    {{ item.aguinaldo ? format( item.aguinaldo ) : 'NA' }}
                  </template>

                  <template v-slot:item.sueldos="{ item }">
                    <v-btn color="success" icon small @click="sueldos = item.sueldos; dialog_editar = true" ><v-icon>mdi-eye</v-icon></v-btn>
                  </template>

                  <template v-slot:item.turno="{ item }">
                    <div v-if="item.turno == 2 && item.id_plantel != 13">08:00 Am</div>
                    <div v-if="item.turno == 1 && item.id_plantel != 13">16:00 PM</div>
                    <div v-if="item.id_plantel == 13">17:00 Am </div>
                  </template>

                  <template v-slot:item.descargado="{ item }">
                    <v-btn 
                      :color="item.descargado ? 'success' : 'red' " 
                      icon 
                      small 
                      @click="sueldos = item.sueldos; dialog_editar = true" 
                    >
                      <v-icon v-text="item.descargado ? 'mdi-check' : 'mdi-close' "></v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.validado="{ item }">
                    <v-radio-group
                      v-model="item.validado"
                      column
                      @change="validarPago( item )"
                    >
                      <v-radio
                        label="Correcto"
                        :value="1"
                        color="green"
                      ></v-radio>
                      <v-radio
                        label="Incorrecto"
                        :value="2"
                        color="red"
                      ></v-radio>
                    </v-radio-group>
                  </template>


                  <template v-slot:item.estatus="{ item }">
                    <v-chip small color="grey"  dark v-if="item.estatus == 0" @click="verComentarios( item )"> Pendiente </v-chip>
                    <v-chip small color="red"   dark v-if="item.estatus == 2" @click="verComentarios( item )"> Rechazado </v-chip>
                    <v-chip small color="green" dark v-if="item.estatus == 1" @click="verComentarios( item )"> Aceptado </v-chip>
                  </template>

                  <template v-slot:item.comprobante="{ item }">
                    <v-icon v-if="item.comprobante" @click="verComprobante( item )">mdi-clipboard-check</v-icon>
                  </template>


                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      tile
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar-->
    <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headersSueldos"
            :items="sueldos"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogComentarios"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Comentarios</span>
        </v-card-title>

        <v-card-text >
          <v-row>
            <v-col cols="12" v-for="(mensaje, i) in comentarios" :key="i" :class="`${mensaje.id_trabajador != getdatosUsuario.id_trabajador ? 'pl-10' : 'pr-10'}`">
              <p 
                :class="`pa-2 ma-0 mb-4 ${ mensaje.id_trabajador != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
                {{ mensaje.motivo }}
                <br/>
                {{ mensaje.nombre_completo }}
              </p>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-text>
          <v-textarea
            label="motivo"
            v-model="comentario"
            :rows="3"
            hide-details
            filled
            auto-grow
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogComentarios = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="enviarComentario() "
          >
            enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px">
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-aguinaldo/firma-${editedItem.id}.pdf`" 
          width="100%" 
          :height="tamanioPantalla" 
        >
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activos: null,
      puestos:[],
      puesto_seleccionado: null,
      plantel: null,
      planteles:[],
      perfiles:[],
      puestos:[],
      jornada:[],
      tipo_trabajador:[],
      nivel:[],
      curso:[],
      trabajadores:[],
      trabajadorERP:[],
      usua:[],
      departamento:[],
      valor:0,

      fecha_nacimiento:0,
      fecha_termino_labores:null,
      fecha_ingreso:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      dialogSueldos: {
        estatus: false
      },

      categoriasVendedoras:[],

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
       search:'',
       page: 1,
       pageCount: 0,
       itemsPerPage: 10,
       horarios: [],
       headers: [
        { text: 'ID'                    , value: 'id'              },
        { text: 'Trabajador'            , value: 'nombre_completo' },
        { text: 'Puesto'                , value: 'puesto'          },
        { text: 'Depto'                 , value: 'departamento'    },
        { text: 'Fecha ingreso'         , value: 'fecha_ingreso'   },
        { text: 'Cierre Año'            , value: 'cierre_anio'     },
        { text: 'Días antigüedad'       , value: 'antiguedad'      },
        { text: 'Sueldo Mensual'        , value: 'sueldo_mensual'  },
        { text: 'Sueldo Semanal'        , value: 'sueldo_semanal'  },
        { text: 'Sueldo Diario'         , value: 'sueldo_dia'      },
        { text: 'Días aguinaldo'        , value: 'dias_aguinaldo'  },
        { text: 'Aguinaldo'             , value: 'aguinaldo'       },
        { text: 'Estatus'               , value: 'estatus'         },
        { text: 'Descargado'            , value: 'descargado'      },
        { text: 'Comprobante'           , value: 'comprobante'     },
        { text: 'Sueldos'               , value: 'sueldos'         },
        { text: 'Validado'              , value: 'validado'        },
        { text: 'Turno'                 , value: 'turno'           },
      ],

      headersSueldos: [
        { text: 'Suelod'            , value: 'sueldo_mensual' },
        { text: 'Ciclo'                , value: 'ciclo'           },
      ],

      editedItem: {
        id:0
      },

      sueldos: [],

      usuarios: [],
      disponibilidades:[],
      categorias:[],
      vacantes:[],
      reglamento: false,
      descriptor: false,
      misionvision: false,
      confidencialidad: false,
      dialogVerDocumentos: false,
      files:'',

      url:'',

      estatusAll:[
        { idestatus: 1, estatus: 'Aceptado' },
        { idestatus: 2, estatus: 'Rechazado' },
        { idestatus: 0, estatus: 'Pendiente' },
        { idestatus: 3, estatus: 'Todo' },
      ],
      estatus: 3,

      dialogPDF: false,

      selected:[],

      sinTeacher: null,
      dialogComentarios: false,
      comentarios: [],
      firmaUsuario: null,
      comentario: null

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return 'Agregar Usuario'
      },

      formTitle2 () {
        return 'Editar Usuario'
      },

      filterTrabajadores( ){
        let data = this.trabajadorERP

        if( this.puesto_seleccionado ){
          data = data.filter( el=> { return el.id_puesto == this.puesto_seleccionado })
        }

        if( this.estatus != 3 ){
          data = data.filter( el=>  el.estatus == this.estatus )
        }

        if( this.sinTeacher ){
          console.log( this.sinTeacher )

          data = data.filter( el => ![82,10].includes( el.id_puesto) )
        }

        return data 
      },

      total( ){

        return this.filterTrabajadores.filter( el => el.aguinaldo ).map(item => item.aguinaldo).reduce((prev, curr) => prev + curr, 0)

      },


      avance( ){


        let trabajadores = this.filterTrabajadores.length
        let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length


        return (( firmados / trabajadores ) * 100 ).toFixed( 2 )
      },

      firmados( ){

        let trabajadores = this.filterTrabajadores.length
        let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length

        return firmados
      },

      faltan( ){

        let trabajadores = this.filterTrabajadores.length
        let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length

        return trabajadores - firmados
      },

      empleadosTotal( ){

        let trabajadores = this.filterTrabajadores.length

        return trabajadores
      },

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
      },

      width(){
        return this.$vuetify.breakpoint.width
      },

      height(){
        return this.$vuetify.breakpoint.height - 250
      }

    },

    watch: {
      dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

      this.url = axios.defaults.baseURL  + 'comprobantes-imss/'
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
    },

    methods: {
      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('aguinaldo.calculo').then(response=>{
          this.trabajadorERP = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verDocumentos( item ){
        this.reglamento = item.reglamento
        this.descriptor = item.descriptor
        this.misionvision = item.misionvision
        this.confidencialidad = item.confidencialidad
        this.dialogVerDocumentos = true
      },

      getUsuarios( ){
        this.cargar = true
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
        this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPerfiles () {
        this.cargar = true
        this.perfiles = []
        return this.$http.get('usuarios.erp.get.perfilesERP').then(response=>{
          this.perfiles = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestos = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false
          this.puestos = response.data
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log( this.editedItem  )
        this.dialog_editar = true
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('horarios.eliminar/' + this.editedItem.id_horario, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.defaultItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      verComentarios( item ){

        this.firmaUsuario = item
        this.comentarios  = item.comentarios
        this.dialogComentarios = true

      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

      enviarComentario( ){

        const payload = {
          motivo_rechazo: this.comentario,
          idfirma_aguinaldo: this.firmaUsuario.idfirma_aguinaldo,
          id_usuario: this.getdatosUsuario.id_trabajador
        }

        return this.$http.post('responder.aguinaldo', payload).then(response=>{
          this.initialize()
          this.dialogComentarios = false
          this.firmaUsuario = null
          this.comentarios  = []
          this.comentario = ''
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },  

      download( ){

        if( !this.selected.length ){

          return this.validarErrorDirecto('Favor de seleccionar al menos un archivo para descargar')

        }

        this.cargar = true
        return this.$http.post('download.fimras.aguinaldos', this.selected).then(response=>{
          this.cargar      = false

          let url = axios.defaults.baseURL + 'imagenes-aguinaldos/' + response.data.archivo 

          window.open(url, '_blank');

          this.initialize( )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      exportarDataExcel( ){

        // const alumnos = this.filterTrabajadores.map(({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }) => ({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }));

        // this.exportExcel( alumnos, 'Trabajadores')


        this.exportExcel( this.filterTrabajadores, 'trabajadores')

      },

      format( valor ){
        return valor.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN'
        });
      },

      verComprobante( item ){
        this.editedItem = Object.assign({}, item)
        this.dialogPDF = true
      },

      validarPago( item ){


        const { id, validado } = item 

        const payload = { id, validado }

        this.cargar = true
        // Lo mandapos por el EP
        
        this.$http.post('valida.aguinaldo', payload ).then(response=>{

          this.cargar = true
          // this.initialize()
        
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }
    },
  }
</script>

