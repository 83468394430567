<template>
  <div
    :style="`height: ${tamanioPantalla}px; overflow: auto;`" 
    id="preguntas"
  >
    <v-list dense class="elevation-0">
      <template v-for="(chatW, i) in filterChats" :kei="i" v-if="i <= limite">
        <v-divider inset v-if="i > 0"></v-divider>

        <v-list-item
          @click="$emit('verChat', chatW ), chat = chatW"
          :class="chatW == chat ? 'grey lighten-3' : '' "
        >
          <!-- AVATAR -->
          <v-list-item-avatar :color="!chatW.nombre ? '#00A548' : 'grey lighten-3'">
            <v-icon color="white" v-if="!chatW.nombre">mdi-account</v-icon>
            <span v-if="chatW.nombre">{{ chatW.nombre }}</span>
          </v-list-item-avatar>

          <!-- contenido del contacto -->
          <v-list-item-content>

            <!-- Nombre del contacto -->
            <v-list-item-title>
              <v-icon v-if="chatW.idetiquetas" :color="chatW.color">mdi-label</v-icon>
              <v-icon small color="orange" v-if="chatW.reasignado">mdi-circle</v-icon>{{ getNombre(chatW) }}
              <v-chip x-small v-if="chatW.idetapa == 1" dark color="red">Atrasado</v-chip>
              <v-chip x-small v-if="chatW.idetapa == 2" dark color="green">Nuevo</v-chip>
              <v-chip x-small v-if="chatW.idetapa == 3" dark color="pink">Inducción</v-chip>
              <v-chip x-small v-if="chatW.idetapa == 4" dark color="orange">Con info</v-chip>
              <v-chip x-small v-if="chatW.idetapa == 5" dark color="brown">Sin info</v-chip>
              <v-chip x-small v-if="chatW.idetapa == 7" dark color="purple darken-4">2do Contacto</v-chip>

              <v-chip x-small color="green" dark v-if="chatW.tiemporestante > 2"       class="ml-2">{{ chatW.tiemporestante }}<v-icon x-small right>mdi-clock</v-icon></v-chip>
              <v-chip x-small color="yellow"     v-else-if="chatW.tiemporestante >= 0" class="ml-2">{{ chatW.tiemporestante }}<v-icon x-small right>mdi-clock</v-icon></v-chip>
              <v-chip x-small color="red"   dark v-else                                class="ml-2">{{ chatW.tiemporestante }}<v-icon x-small right>mdi-clock</v-icon></v-chip>
            </v-list-item-title> 

            <!-- Cuerpo del contacto -->
            <v-list-item-subtitle >
              <!-- VISTO -->
              <v-icon 
                small 
                class="mr-1" 
                v-if="chatW.mio" 
                color="grey"
              >
                mdi-check-all
              </v-icon>


              <!-- MOSTRAR ULTIMO MENSAJE -->
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    > 
                      <span v-if="chatW.type == 1">{{ chatW.mensaje }}</span>
                      <span v-if="chatW.type == 2">Imagen</span>
                      <span v-if="chatW.type == 3">Audio</span>
                      <span v-if="chatW.type == 4">PDF</span>
                    </span>
                  </template>
                  <!-- mostrar texto -->
                  <span v-if="chatW.type == 1">{{ chatW.mensaje }}</span>

                  <!-- Mostrar miniatura de la imagen -->
                  <span v-if="chatW.type == 2">
                    <img v-if="chatW.mio == 0" :src="url2 + chatW.MediaUrl0" alt="Red dot" width="100"/>
                    <img v-if="chatW.mio == 1" :src="url2 + chatW.MediaUrl0" alt="Red dot" width="100"/>
                  </span>

                  <!-- Tipo de mensaje es un audio -->
                  <span v-if="chatW.type == 3">Audio, abrir para escuchar</span>

                  <!-- Tipo PDF -->
                  <span v-if="chatW.type == 4">PDF, abrir para verlo</span>
                </v-tooltip>
              </span>   

            </v-list-item-subtitle>

          </v-list-item-content>


          <v-list-item-icon>
            <!-- HORA DEL CHAT -->
            <v-list-item-action-text >{{  chatW.ultimmensaje }}</v-list-item-action-text>

            <!-- Marcador para saber cuando mensaje no leídos son -->
            <v-chip v-if="chatW.sinleer > 0 || chatW.mio == 0" :color="chatW.mio == 0 ? 'green' : ''" small dark class="pa-2">
              {{ 1 }}
            </v-chip>
          </v-list-item-icon>

        </v-list-item>

        <div v-if="limite == i" >
          <v-btn color="success" @click="limite += 50" block tile>Mostrar más mensajes +50</v-btn>
        </div>
      </template>
    </v-list>
  </div>
</template>
<script>
  import axios          from 'axios';
  import { mapGetters, mapActions } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import MensajesWppTwilio     from '@/components/whatsapp/MensajesTwilioWpp.vue';
  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	props:[
  		'filterChats',
  	],

    components:{
      Alerta,
      carga,
      MensajesWppTwilio,
    },

    mixins: [ validarErrores ],


    data: () => ({
      chat:null,
      limite: 100,
      url :'https://academicokpi.club/imagenes-whatsapp/',
      url2: 'https://escuelakpi.club/imagenes-whatsapp/',
    }),

    computed: {
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height - 330
      },
    },

    watch:{
      
    },

    async created (){

    },

    methods: {
    	getNombre( texto ){

        let nombre = texto.usuario.replace('whatsapp:+52','')

        return nombre
      },
    },
  }
</script>

<style  scoped>

  #mensajesChat{
    background-image: url("/fondo_whatsapp.jpg");
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size:contain;
  }

  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /**********************************/

  #mensajesChat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 750px;
    margin-top: 25px;
  }

  /* ===== MENU ===== */
  .menu {
    float: left;
    height: 700px;;
    width: 70px;
    background: #4768b5;
    background: -webkit-linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: linear-gradient(#4768b5, #35488e);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  }

  .menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
  }

  .menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
  }

  .menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
  }

  .menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
  }

  /* === CONVERSATIONS === */

  .discussions {
    width: 100%;
    height: 702px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
    overflow: hidden;
    display: inline-block;
  }

  .discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
  }

  .discussions .search .searchbar {
    height: 40px;
    background-color: #FFF;
    width: 70%;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
  }

  .discussions .search .searchbar *::-webkit-input-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *::-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-ms-input-placeholder {
      color: #E0E0E0;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

</style>
