<template>
  <v-dialog
    v-model="dialogVendedora.estatus"
    max-width="1400"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Reporte por vendedoras</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( vendedoras  , 'ASIGNACION_TWILIO')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>

        <v-row>
          <v-col cols="12" class="black--text text-h6" >
            <b>Tiempo de respuesta promedio:</b> {{ promedioTiempo }}
          </v-col>  
        </v-row>

		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="vendedoras"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

              <template v-slot:item.utlimocontacto="{ item }">
                <v-icon color="green" small right v-if="item.minutos < 20">mdi-circle</v-icon>
                <v-icon color="yellow" small right v-if="item.minutos >= 20 && item.minutos <= 59 ">mdi-circle</v-icon>
                <v-icon color="red" small right v-if="item.minutos >= 60">mdi-circle</v-icon>
                <span @click="mensaje = item.utlimomensaje, dialogVerMensaje = true">{{ item.utlimocontacto }}</span>
              </template>

              <template v-slot:item.espera="{ item }">
                <v-btn 
                  color="orange" 
                  x-small
                  @click="tabla = item.espera_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.espera}}
                </v-btn>
              </template>

              <template v-slot:item.verdes="{ item }">
                <v-btn 
                  color="green" 
                  x-small
                  @click="tabla = item.verdes_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.verdes}}
                </v-btn>
              </template>

              <template v-slot:item.amarillos_mios="{ item }">
                <v-btn 
                  color="yellow" 
                  x-small
                  @click="tabla = item.amarillos_mios_C, dialogContactos = true"
                  class="mr-2"
                >
                  {{item.amarillos_mios}}
                </v-btn>
              </template>

              <template v-slot:item.rojos_mios="{ item }">
                <v-btn 
                  color="red" 
                  x-small
                  @click="tabla = item.rojos_mios_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.rojos_mios}}
                </v-btn>
              </template>


              <template v-slot:item.verdes_mios="{ item }">
                <v-btn 
                  color="green" 
                  x-small
                  @click="tabla = item.verdes_mios_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.verdes_mios}}
                </v-btn>
              </template>

              <template v-slot:item.amarillos="{ item }">
                <v-btn 
                  color="yellow" 
                  x-small
                  @click="tabla = item.amarillos_C, dialogContactos = true"
                  class="mr-2"
                >
                  {{item.amarillos}}
                </v-btn>
              </template>

              <template v-slot:item.rojos="{ item }">
                <v-btn 
                  color="red" 
                  x-small
                  @click="tabla = item.rojos_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.rojos}}
                </v-btn>
              </template>


					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="dialogVendedora.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogContactos"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Contactos 
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersContactos"
            :items="tabla"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVerMensaje"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Último mensaje enviado 
        </v-card-title>
        <v-card-text v-if="mensaje" class="black--text">
          Usuario: {{ mensaje.usuario }}
          <br/>
          Fecha: {{ mensaje.fecha_ultimo_mensaje }}
          <br/>
          Mensaje: 
          <br/>
          <div>
            <!-- Tipo de texto es textooo -->
            <p 
              class="text" 
              v-if="mensaje.type == 1"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Tipo de texto es Imagennnnn -->
            <p 
              class="text" 
              v-if="mensaje.type == 2"
              style="white-space: pre-line"
              @click="verImagen( mensaje.MediaUrl0 )"
              type="button"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0" alt="Red dot" width="300" />
              <img v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="300" />

              <!-- Ruta para imagenes fake -->
              <img v-if="mensaje.fake" :src="mensaje.imagenFake" alt="Red dot" width="300" />
            </p>

            <!-- Para cuando se envían stickers -->
            <p 
              class="text" 
              v-if="mensaje.type == 'sticker'"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" alt="Red dot" width="150"/>
              <img v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="150"/>
            </p>

            <!-- Tipo PDF -->
            <p 
              class="text" 
              v-if="mensaje.type == 4"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <v-chip
                color="#f1f3f4"
                label
                text-color="black"
              >
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>

              </v-chip>
                <embed v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0 " type="application/pdf"  width="100%" height="350px" />
                <embed v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0 " type="application/pdf" width="100%" height="350px" />

                <embed v-if="mensaje.fake" :src="`${mensaje.imagenFake}#toolbar=0`" type="application/pdf" width="100%" height="350px" />
            </p>

            <!-- Tipo Audio -->
            <p 
              class="text pa-0 pt-2 px-2 audio" 
              v-if="mensaje.type == 3"
              style="white-space: pre-line; width: 300px;"
              @contextmenu="show(mensaje, $event)"
            >
              <audio controls style="width: 100%;">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" type="audio/ogg" >
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" :type="`audio/${mensaje.MediaUrl0.substring(mensaje.MediaUrl0.length - 3, mensaje.MediaUrl0.length) == 'mp4' ? 'mp4' : 'mp3'}`" >
              </audio>
            </p>

            <!-- Tipo video -->
            <p 
              class="text" 
              v-if="mensaje.type == 5"
              style="white-space: pre-line"
            >
              <video controls width="300">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0">
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0">
              </video>
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 2 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 4 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            absolute
            top
            right
            @click="dialogVerMensaje = false"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>               
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogVendedora','escuela', 'nivel','leccion', 'contadorVendedoras'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

      defaultItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

			search:'',
      usuarios: [],
      planteles:[],
      headers: [
        { text: "Vendedora"        , value: "vendedora"        },
        { text: "Último Contacto"  , value: "utlimocontacto"   },
        { text: "T. Espera"        , value: "espera"           },
        { text: "Verdes"           , value: "verdes"           },
        { text: "Amarillos"        , value: "amarillos"        },
        { text: "Rojo"             , value: "rojos"            },
        
        { text: "Verdes M salida"    , value: "verdes_mios"   },
        { text: "Amarillos M salida" , value: "amarillos_mios"},
        { text: "Rojo M salida"      , value: "rojos_mios"    },
        
        { text: "Atrasados"     , value: "atrasados"    },
        { text: "Nuevos"        , value: "nuevos"       },
        { text: "Con info"      , value: "coninfo"      },
        { text: "Sin info"      , value: "sininfo"      },
        { text: "2da etapa"     , value: "contacto2"    },
        { text: "Por contestar" , value: "porcontestar" },
        // { text: "Estatus"       , value: "estatus"      },
      ],

      headersContactos:[
        { text: "Folio"         , value: "folio"                  },
        { text: "Telefono"      , value: "usuario"                },
        { text: "Nombre"        , value: "nombre_completo"        },
        { text: "Zona"          , value: "zona"                   },
        { text: "T. Espera"     , value: "tiempo_espera"          },
      ],
      tabla:[],
      dialogContactos: false,


      mensaje: null,
      dialogVerMensaje: false,
      url :'https://testfast.club/imagenes-whatsapp/',
      url2: 'https://escuelakpi.club/imagenes-whatsapp/',
      promedioTiempo: 0

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
      },

    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
    },

    methods: {


	    initialize() {
        this.cargar = true;
        this.vendedoras = []

        const payload = {
          escuela: this.escuela
        }

        return axios.post('crm.vendedoras', payload ).then(response => {

          this.promedioTiempo = response.data.promedio
    		
          let vendedoras = []

          vendedoras = JSON.parse(JSON.stringify(this.contadorVendedoras));

          for( const  i in vendedoras ){

          	const { iderp } = vendedoras[i]

          	vendedoras[i]['atrasados']    = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 1 ).length 
          	vendedoras[i]['nuevos']       = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 2 ).length 
          	vendedoras[i]['coninfo']      = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 4 ).length 
          	vendedoras[i]['sininfo']      = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 5 ).length 
          	vendedoras[i]['contacto2']    = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 7 ).length 
          	vendedoras[i]['porcontestar'] = response.data.vendedoras.filter( el => el.usuario_asignado == iderp ).length 

          }


          let contactosTwilio = response.data.chatsCompletos

          for( const  i in vendedoras ){

            const { iderp } = vendedoras[i]

            let contactos = contactosTwilio.filter( el => el.nombre_completo )

            vendedoras[i]['amarillos']   = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante >= 0 && el.tiemporestante <= 2 ) && el.mio == 0 ).length 
            vendedoras[i]['amarillos_C'] = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante >= 0 && el.tiemporestante <= 2 ) && el.mio == 0 ) 
            vendedoras[i]['verdes']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 0 ).length 
            vendedoras[i]['verdes_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 0 ) 
            vendedoras[i]['rojos']       = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante < 0 && el.mio == 0 ).length 
            vendedoras[i]['rojos_C']     = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante < 0 && el.mio == 0 ) 
            vendedoras[i]['espera']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante >= 0 && el.mio == 0 ).length 
            vendedoras[i]['espera_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante >= 0 && el.mio == 0 ) 


            vendedoras[i]['amarillos_mios']   = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante >= 0 && el.tiemporestante <= 2 ) && el.mio == 1 ).length 
            vendedoras[i]['amarillos_mios_C'] = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante >= 0 && el.tiemporestante <= 2 ) && el.mio == 1 ) 
            vendedoras[i]['verdes_mios']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 1 ).length 
            vendedoras[i]['verdes_mios_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 1 ) 
            vendedoras[i]['rojos_mios']       = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante < 0 && el.mio == 1 ).length 
            vendedoras[i]['rojos_mios_C']     = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante < 0 && el.mio == 1 ) 

            let contactosGenerales = contactos.filter( el => el.usuario_asignado == iderp && el.mio == 1 )

            contactosGenerales = contactosGenerales.sort((a, b) => new Date(b.fecha_ultimo_mensaje) - new Date(a.fecha_ultimo_mensaje));

            let ultimoContacto = contactosGenerales[0]

            if( ultimoContacto ){

              vendedoras[i]['utlimocontacto']       =  ultimoContacto.minutos_transcurridos
              vendedoras[i]['utlimomensaje']        =  ultimoContacto
              vendedoras[i]['tiempo_espminutosera'] =  ultimoContacto.tiempo_espera
              vendedoras[i]['minutos']              =  ultimoContacto.minutos

            }

            // Buscar el contacto con mayor tiempo de espera
            let contactosEnEspera = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante >= 0 && el.mio == 0 )

            contactosEnEspera = contactosEnEspera.sort((a, b) => new Date(b.tiempo_espera_usuario) - new Date(a.tiempo_espera_usuario));

            if( contactosEnEspera.length ){

              console.log( contactosEnEspera[0] )

              vendedoras[i]['contacto_espera_mayor_tiempo'] =  contactosEnEspera[0].tiempo_espera

            }
          }


          vendedoras.push({
            vendedora     : 'Total',
            atrasados     : vendedoras.map(item => item.atrasados).reduce((prev, curr) => prev + curr, 0),
            espera        : vendedoras.map(item => item.espera).reduce((prev, curr) => prev + curr, 0),
            amarillos     : vendedoras.map(item => item.amarillos).reduce((prev, curr) => prev + curr, 0),
            rojos         : vendedoras.map(item => item.rojos).reduce((prev, curr) => prev + curr, 0),
            verdes        : vendedoras.map(item => item.verdes).reduce((prev, curr) => prev + curr, 0),
            amarillos_mios: vendedoras.map(item => item.amarillos_mios).reduce((prev, curr) => prev + curr, 0),
            rojos_mios    : vendedoras.map(item => item.rojos_mios).reduce((prev, curr) => prev + curr, 0),
            verdes_mios   : vendedoras.map(item => item.verdes_mios).reduce((prev, curr) => prev + curr, 0),
            nuevos        : vendedoras.map(item => item.nuevos).reduce((prev, curr) => prev + curr, 0),
            coninfo       : vendedoras.map(item => item.coninfo).reduce((prev, curr) => prev + curr, 0),
            sininfo       : vendedoras.map(item => item.sininfo).reduce((prev, curr) => prev + curr, 0),
            contacto2     : vendedoras.map(item => item.contacto2).reduce((prev, curr) => prev + curr, 0),
            porcontestar  : vendedoras.map(item => item.porcontestar).reduce((prev, curr) => prev + curr, 0),
            estatus       : '',
          })


          this.vendedoras = JSON.parse(JSON.stringify(vendedoras));

          this.cargar = false;

        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;

  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .noresponse .text{
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;
  }

  .response .text {
    background-color: #c5e6c1 !important;
    color: black;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;

  }

  .audio {
    background-color: #f1f3f4 !important;
  }

  .response .audio {
    background-color: #f1f3f4 !important;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;

  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }
</style>