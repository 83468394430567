<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
    	<v-card-title primary-title class="text-subtitle-1">
    	  Enviar mensajes
    	</v-card-title>
    	<v-card-text class="black--text">

    		Llena los siguientes datos por favor

    		<br/>
    		<br/>

    		Encuesta:
    		<br/>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">

    				<label>Elige la cuenta de dónde se enviará el mensaje</label>
		    		<v-radio-group
				      v-model="cuenta"
				      column
				    >
				      <v-radio
				        label="Inbi MTY"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Inbi CDMX"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="Inbi GDL"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Fast MTY"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>


    				<label>Ingresa tu número de teléfono</label>
		    		<v-text-field
		    		  name="name"
		    		  label="Teléfono"
		    		  filled
		    		  v-model="telefono"
		    		  type="number"
		    		></v-text-field>

    				<label>Elige la modalidad</label>
		    		<v-radio-group
				      v-model="modalidad"
				      row
				    >
				      <v-radio
				        label="Presencial"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Online"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>

				    <label>Elige el curso</label>
		    		<v-radio-group
				      v-model="curso"
				      column
				    >
				      <v-radio
				        label="Teens"
				        :value="1"
				        v-if="cuenta != 4"
				      ></v-radio>
				      <v-radio
				        label="Adultos"
				        :value="2"
				        v-if="cuenta != 4"
				      ></v-radio>
				      <v-radio
				        label="Adultos"
				        :value="3"
				        v-if="cuenta == 4"
				      ></v-radio>
				    </v-radio-group>

    				<label>Selecciona un plantel</label>
		    		<v-select
              :items="planteles.filter( el => el.zona == cuenta)"
              v-model="monterrey"
              item-text="nom"
              item-value="id"
              label="Sucursal de intéres"
              filled
              dense
            ></v-select>

    				<label>Elige un horario</label>
		    		<v-radio-group
				      v-model="disponibilidad"
				      column
				    >
				      <v-radio
				        label="Lunes a viernes Matutino"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Lunes a viernes Vespertino"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="Sabatino"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Dominical"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>

				    <v-btn 
				    	color="success"
				    	tile
				    	small
				    	@click="generarFolio( )"
				    >
				  		Generar folio
				  	</v-btn>
    			</v-col>
    		</v-row>
    	</v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
	import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	components:{
      Alerta,
      carga
    },

    mixins: [ validarErrores ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	edad: null,
    	modalidad: null,
    	disponibilidad: null,
    	hermanos: null,
    	telefono:'',

    	promociones: [],
    	escuela: null,

    	curso: 1,
    	completo:0,
    	monterrey: 0,

    	planteles: [
    		{id:7, nom: 'FAST_APODACA', zona: 4},
      	{id:8, nom:'FAST_NORIA', zona: 4},
      	{id:9, nom:'FAST_RG', zona: 4},
      	{id:10,nom:'FAST_LV', zona: 4},
      	{id:11,nom:'FAST_ELOY', zona: 4},
      	{id:12,nom:'FAST_MTR', zona: 4},
      	{id:14,nom:'FAST_UNI', zona: 4},
      	{id:16,nom:'FAST ONLINE', zona: 4},
      	{id:1, nom: 'ANAHUAC', zona: 1},
      	{id:2, nom:'Miguel Aleman', zona: 1},
      	{id:3, nom:'Pablo Livas', zona: 1},
      	{id:4, nom:'San Miguel', zona: 1},
      	{id:5, nom:'Casa blanca', zona: 1},
      	{id:6, nom:'Fresnos', zona: 1},
      	{id:17,nom:'INBI ONLINE', zona: 1},
      	{id:21,nom:'APODACA CENTRO', zona: 1},
      	{id:22,nom:'LINCOLN', zona: 1},
      	{id:23,nom:'ESCOBEDO', zona: 1},
      	{id:24,nom:'SANTA CATARINA', zona: 1},
      	{id:25,nom:'MONTEVIDEO', zona: 2},
      	{id:27,nom:'ARAGON', zona: 2},
      	{id:28,nom:'MISTERIOS', zona: 2},
      	{id:29,nom:'TRANSITO', zona: 3},
      	{id:31,nom:'CUITLAHUAC', zona: 2},
      	{id:32,nom:'APODACA MM', zona: 1},
      	{id: 10002, nom: 'EL ROSARIO', zona: 3},
      	{id: 10003, nom: 'BALBUENA', zona: 3},
      	{id: 10004, nom: 'REVOLUCION', zona: 3},
    	],

    	cuenta: 1,

    }),

    created( ){

    },

    methods:{

    	generarFolio( ){

    		if( !this.telefono ){

    			return this.validarErrorDirecto('Favor de ingresar un número de teléfono')

    		}

    		let completo = 0

    		if( this.modalidad && this.monterrey && this.disponibilidad ){

    			completo = 1

    		}

    		const payload = {
					telefono       : this.telefono,
					modalidad      : this.modalidad,
					monterrey      : this.monterrey,
					disponibilidad : this.disponibilidad,
					cuenta         : this.cuenta,
					curso          : this.curso,
					completo       
    		}

    		this.cargar = true;

    		let ruta = this.cuenta == 4 ? 'twilio.pruebas' : 'twilio.pruebas.inbi'
	      this.$http.post(ruta, payload ).then((response) => {
	        this.cargar = false;
	        this.modalidad = null
					this.monterrey = null
					this.disponibilidad = null
					this.curso = null
	        this.validarSuccess( response.data.message )
	      }).catch((error) => {
	        this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });

    	}

    },

    computed:{
      ...mapGetters('login',['getEscuela','getdatosUsuario']),

    	url () {

    		let escuela = [334,1].includes( this.getdatosUsuario.iderp ) ? this.escuela : this.getEscuela

    		let promociones = this.promociones.filter( el => el.escuela == escuela )

    		console.log( this.getEscuela )

    		let api = 'https://escuelakpi.club/kpi/imagenes-promociones/'
        
        let link = ''

        if( this.edad && this.modalidad && this.horario && this.hermanos ){

        	let promo = promociones.find( el => el.edad == this.edad && el.modalidad == this.modalidad && el.horario == this.horario && el.hermanos == this.hermanos )

        	if( promo ){
        		link = api + promo.imagen
        	}

        }

        return link
    	},

    	terminos( ){

    		let escuela = [334,1].includes( this.getdatosUsuario.iderp ) ? this.escuela : this.getEscuela

    		let nombreArchivo = escuela == 2 ? 'terminos_fast.jpeg' : 'terminos_inbi.jpeg'

    		let link = 'https://escuelakpi.club/kpi/imagenes-promociones/' + nombreArchivo

        return link

    	},

    },


  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>