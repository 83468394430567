<template>
  <div>
    <v-file-input
      v-if="!viewOnly"
      v-model="newFiles"
      multiple
      show-size
      accept=".jpg,.jpeg,.png,.bmp,.pdf,.xlsx,.docx,.doc,.pptx,.ppt"
      :rules="rules"
      prepend-icon="mdi-file-upload"
      label="Selecciona archivos"
      @change="handleFileChange"
      chips
      counter
      :disabled="isUploading"
    ></v-file-input>

    <!-- Grid de previsualizaciones -->
    <v-row v-if="previews.length > 0">
      <v-col
        v-for="(preview, index) in previews"
        :key="preview.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card
          :class="{'selected-file': preview.selected}"
          @click="toggleFileSelection(index)"
        >
          <v-card-text class="pa-3">
            <!-- Preview para imágenes -->
            <div v-if="isImage(preview.type)" class="preview-container">
              <v-img
                :src="preview.url"
                aspect-ratio="1"
                contain
                max-height="150"
                @click.stop="openPreview(preview)"
              ></v-img>
            </div>
            <!-- Preview para PDFs -->
            <div v-else-if="isPDF(preview.type)" class="preview-container d-flex justify-center align-center">
              <v-icon
                size="64"
                color="red"
                @click.stop="openPreview(preview)"
                class="pdf-icon"
              >
                mdi-file-pdf-box
              </v-icon>
            </div>
            <!-- Preview para Excel -->
            <div v-else-if="isOfficeFile(preview.type)" class="preview-container d-flex justify-center align-center">
              <v-icon
                size="64"
                :color="getFileColor(preview.type)"
                @click.stop="downloadFile(preview)"
                class="office-icon"
                title="Clic para descargar"
              >
                {{ getFileIcon(preview.type) }}
              </v-icon>
            </div>
            <div v-else-if="isPowerPoint(preview.type)" class="preview-container d-flex justify-center align-center">
              <v-icon
                size="64"
                :color="getFileColor(preview.type)"
                @click.stop="downloadFile(preview)"
                class="office-icon"
                title="Clic para descargar"
              >
                {{ getFileIcon(preview.type) }}
              </v-icon>
            </div>
            <!-- Otros tipos de archivo -->
            <div v-else class="preview-container d-flex justify-center align-center">
              <v-icon size="64" :color="getFileColor(preview.type)">
                {{ getFileIcon(preview.type) }}
              </v-icon>
            </div>

            <v-list-item three-line class="px-0">
              <v-list-item-content>
                <v-list-item-title class="text-truncate">
                  {{ preview.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatFileSize(preview.size) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption">
                  {{ getFileTypeLabel(preview.type) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn
              text
              small
              color="primary"
              @click.stop="downloadFile(preview)"
            >
              <v-icon left>mdi-download</v-icon>
              Descargar
            </v-btn> -->
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="error"
              @click.stop="removeFile(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog para previsualización -->
    <v-dialog
      v-model="previewDialog"
      max-width="90vw"
      @click:outside="closePreview"
    >
      <v-card v-if="selectedPreview">
        <v-card-title class="headline">
          {{ selectedPreview.name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closePreview">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="preview-dialog-content">
          <!-- Previsualización de imagen -->
          <v-img
            v-if="isImage(selectedPreview.type)"
            :src="selectedPreview.url"
            contain
            max-height="80vh"
          ></v-img>
          <!-- Previsualización de PDF -->
          <iframe
            v-else-if="isPDF(selectedPreview.type)"
            :src="selectedPreview.url"
            width="100%"
            height="100%"
            type="application/pdf"
            class="pdf-iframe"
          >
            <p>Tu navegador no soporta la visualización de PDFs</p>
          </iframe>
        </v-card-text>
          <!-- Mensaje para otros tipos de archivo -->
          <!-- <div v-else class="text-center pa-4">
            <v-icon size="64" :color="getFileColor(selectedPreview.type)">
              {{ getFileIcon(selectedPreview.type) }}
            </v-icon>
            <p class="mt-2">Vista previa no disponible para este tipo de archivo</p>
          </div> -->
          <v-card-actions>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'EvidenciasMultiples',

  props: {
    existingFiles: {
      type: Array,
      default: () => []
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    existingFiles: {
    immediate: true,
    handler(newFiles) {
      if (newFiles && newFiles.length) {
        const existingPreviews = newFiles.map(file => ({
          id: file.identregables_evidencias, // Usar el ID real de la base de datos
          name: file.evidencia,
          type: this.getFileTypeFromExtension(file.extension),
          url: `${axios.defaults.baseURL}imagenes-entregables/${file.evidencia}`,
          size: 0,
          isExisting: true,
          selected: false,
          dbId: file.identregables_evidencias // Mantener el ID de la BD separado si es necesario
        }));

        this.previews = [...existingPreviews];
      }else {
        this.preview = []
      }
    }
  },
  },

  data: () => ({
    newFiles: null,
    files: [],
    previews: [],
    previewDialog: false,
    selectedPreview: null,
    isUploading: false,
    rules: [
      value => {
        if (!value || !value.length) return true
        const totalFiles = value.length
        if (totalFiles > 5) return 'No puedes subir más de 5 archivos a la vez'
        return true
      },
      file => {
        if (!file) return true
        const maxSize = 5 * 1024 * 1024 // 5MB
        const valid = file.size < maxSize
        return valid || 'El archivo no debe exceder 5MB'
      }
    ]
  }),

  methods: {
    isOfficeFile(fileType) {
      return this.isExcel(fileType) || this.isWord(fileType) || this.isPowerPoint(fileType);
    },

    isPowerPoint(fileType) {
      return fileType.includes('presentation') || fileType.includes('powerpoint');
    },

    isExcel(fileType) {
      return fileType.includes('sheet') || fileType.includes('excel');
    },

    isWord(fileType) {
      return fileType.includes('word') || fileType.includes('document');
    },


    getFileTypeFromExtension(extension) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'webp'];
      const ext = extension?extension.toLowerCase() : ''

      if (imageExtensions.includes(ext)) {
        return 'image/' + ext;
      }
      if (ext === 'pdf') {
        return 'application/pdf';
      }
      if (ext === 'xlsx' || ext === 'xls') {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      if (ext === 'docx' || ext === 'doc') {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      if (ext === 'pptx' || ext === 'ppt') {
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      }
      return 'application/octet-stream';
    },

    getFileColor(fileType) {
      if (fileType.includes('pdf')) return 'red';
      if (fileType.includes('sheet') || fileType.includes('excel')) return 'green';
      if (fileType.includes('word') || fileType.includes('document')) return 'blue';
      if (fileType.includes('powerpoint') || fileType.includes('presentation')) return 'orange';
      return 'grey';
    },

    getFileTypeLabel(fileType) {
      if (this.isImage(fileType)) return 'Imagen';
      if (this.isPDF(fileType)) return 'PDF';
      if (this.isExcel(fileType)) return 'Excel';
      if (this.isPowerPoint(fileType)) return 'PowerPoint';
      if (this.isWord(fileType)) return 'Word';
      return 'Documento';
    },

    async downloadFile(preview) {
      try {
        let url;
        if (preview.isExisting) {
          // Para archivos existentes, usar la URL del servidor
          url = preview.url;
        } else {
          // Para archivos nuevos, usar el objeto File
          url = preview.url;
        }

        // Crear un enlace temporal
        const link = document.createElement('a');
        link.href = url;
        link.download = preview.name; // Nombre del archivo para la descarga
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        if (!preview.isExisting) {
          // Solo revocar URL para archivos nuevos
          URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
        this.$emit('error', 'Error al descargar el archivo');
      }
    },

    handleFileChange(files) {
      if (!files) {
        this.newFiles = null
        return
      }

      const fileArray = Array.isArray(files) ? files : Array.from(files)

      // Verificar si el total de archivos no excede el límite
      if (this.previews.length + fileArray.length > 5) {
        this.$emit('error', 'No puedes subir más de 5 archivos en total')
        this.newFiles = null
        return
      }

      // Crear previsualizaciones para los nuevos archivos
      const newPreviews = fileArray.map(file => ({
        id: Date.now() + Math.random(), // ID único para cada archivo
        file,
        name: file.name,
        size: file.size,
        type: file.type,
        url: this.createFilePreview(file),
        selected: false
      }))

      // Agregar las nuevas previsualizaciones al array existente
      this.previews = [...this.previews, ...newPreviews]
      this.files = [...this.files, ...fileArray]

      // Emitir el evento con todos los archivos
      this.$emit('files-changed', this.files)

      // Limpiar el input para permitir seleccionar el mismo archivo nuevamente
      this.newFiles = null
    },

    createFilePreview(file) {
      if (this.isImage(file.type) || this.isPDF(file.type)) {
        return URL.createObjectURL(file)
      }
      return null
    },

    removeFile(index) {
      const preview = this.previews[index];

      if (preview.isExisting) {
        const fileId = preview.dbId || preview.id;
        this.$emit('remove-existing-file', {
          fileId: preview.dbId || preview.id, // Usar el ID correcto de la BD
          fileName: preview.name
        });
      }

      if (preview.url) {
        URL.revokeObjectURL(preview.url);
      }

      this.previews.splice(index, 1);
      if (!preview.isExisting) {
        this.files.splice(index, 1);
      }
      this.$emit('files-changed', this.files);
    },

    toggleFileSelection(index) {
      this.previews[index].selected = !this.previews[index].selected
    },

    openPreview(preview) {
      if (this.isImage(preview.type) || this.isPDF(preview.type)) {
        this.selectedPreview = preview
        this.previewDialog = true
      }
    },

    closePreview() {
      this.previewDialog = false
      this.selectedPreview = null
    },

    isImage(fileType) {
      return fileType.startsWith('image/')
    },

    isPDF(fileType) {
      return fileType === 'application/pdf'
    },


    getFileIcon(fileType) {
      if (fileType.includes('powerpoint') || fileType.includes('presentation')) return 'mdi-file-powerpoint';
      if (fileType.includes('pdf')) return 'mdi-file-pdf-box';
      if (fileType.includes('sheet') || fileType.includes('excel')) return 'mdi-file-excel';
      if (fileType.includes('word') || fileType.includes('document')) return 'mdi-file-word';
      return 'mdi-file-document-outline';
    },


    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const sizes = ['Bytes', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }
  },

  beforeDestroy() {
    // Limpiar las URLs de objeto creadas
    this.previews.forEach(preview => {
      if (preview.url) {
        URL.revokeObjectURL(preview.url)
      }
    })
  }
}
</script>

<style scoped>
.preview-container {
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.pdf-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.pdf-iframe {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  aspect-ratio: 16/9; /* o cualquier otra proporción de aspecto que se adapte a tus necesidades */
  overflow: auto;
}

.pdf-icon {
  position: absolute;
  z-index: 1;
}

.selected-file {
  border: 2px solid var(--v-primary-base);
}

.v-image {
  max-width: 100%;
  object-fit: contain;
}

.preview-dialog-content {
  max-height: 80vh;
  overflow: auto;
}

/* Asegurar que el iframe del PDF tenga fondo blanco */
iframe {
  background: white;
}

.excel-icon {
  cursor: pointer;
}

.excel-icon:hover {
  opacity: 0.8;
}

.preview-container {
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.office-icon {
  cursor: pointer;
}

.office-icon:hover {
  opacity: 0.8;
}
</style>
