
// Importación de librerias del proyecto
import '@/plugins/vue-composition-api'
import Vue            from "vue";
import App            from "./App.vue";
import router         from "./router";
import store          from "./store";
import vuetify        from "./plugins/vuetify";
import VueResource    from "vue-resource";
import config         from 'dotenv';
import VueSweetalert2 from 'vue-sweetalert2';
import axios          from 'axios';
import { io }         from "socket.io-client";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-organization-chart/dist/orgchart.css';

// Configurarmos el socket

// Modo servidor
const path = '/ws/socket.io'
let urlTwilio = 'https://testfast.club/'

// iniciar el socket
const socket = io(urlTwilio, {
  transports: ["websocket"], path
});


// Configuración de herramientas
config.config();
Vue.config.productionTip = false;


// Importar componentes de manera global
Vue.use(VueSweetalert2);


// Confugyración de la ruta base del proyecto modo local y modo desarrollo
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:3004/' : process.env.VUE_APP_RUTA_API

// axios.interceptors.request.use(config => config);
const datosUsuario = store.state.login.datosUsuario.iderp;

// Aquí mandamos los datos del usuario como un di, para la recepción en el back y poder saber si tiene acceso o no y sacarlo
axios.interceptors.request.use(config => {
  // Agregar encabezados personalizados (por ejemplo, token de autorización)
  config.headers.Authorization = 'Bearer ' + localStorage.tlaKey;
  config.headers.Accept = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
  config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
  config.headers['user-iD'] = datosUsuario;

  return config;
}, error => {
  // Manejar errores de solicitud
  console.log( error )
  return Promise.reject(error);
});

// Aquí ponemos las variables globales
Vue.prototype.$http   = axios;
Vue.prototype.$socket = socket;

// Constructor de VUE
new Vue({
  router,
  store,
  vuetify,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");


