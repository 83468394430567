<template>
    <v-snackbar
      v-model="alertaSinTeacher.estatus"
      right
      top
      color="white"
      vertical
      class="text-center mb-0 pt-2"
      :timeout="6000"
    >
      <v-alert
        dense
        text
        :type="alertaSinTeacher.type"
        class="pb-0 mb-0"
        hide-details
      >
        <div 
          style="white-space: pre-line"
        >
          {{ alertaSinTeacher.mensaje }}
        </div>
      </v-alert>
      <v-btn 
        color="success"
        absolute
        top
        small
        icon
        right
        @click="alertaSinTeacher.estatus = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'alertaSinTeacher',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',

    }),

    methods:{
      cerrar(){
      	this.alertaSinTeacher.estatus = false
      },

    }
	}
</script>
