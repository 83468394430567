
<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <!-- Botones -->
          <v-card-title primary-title>
            <span class="text-subtitle-1">Servicios Sistemas</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(servicios_sistemas, 'servicios_sistemas_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

            <!-- Filtros -->
            <!-- <v-container fluid>
              <v-row justify="space-around" align="center">
                <v-radio-group
                  v-model="estatus"
                  mandatory
                  row
                  class="d-flex align-center"
                >
                  <v-card-text>
                    <span>Filtrar por estatus</span>
                  </v-card-text>
                  <v-radio label="Todos" :value="10"></v-radio>
                  <v-radio label="Solicitado" :value="0"></v-radio>
                  <v-radio label="Pagado" :value="1"></v-radio>
                </v-radio-group>
              </v-row>
            </v-container> -->
          </v-card-title>

          <!-- Tabla de items -->
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="servicios_sistemas"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.recurrencia="{ item }">
                    {{ getRecurrenciaText(item.recurrencia) }}
                  </template>

                  <template v-slot:item.evidencia="{ item }">
                    <v-img
                      :src="url + item.evidencia"
                      contain
                      max-height="100"
                      aspect-ratio="2"
                      @click="openImageDialog(item.evidencia)"
                      class="clickable-image"
                    />
                  </template>

                  <template v-slot:item.estatus="{ item }">
                    <v-chip :color="getEstatusColor(item.estatus)" dark>
                      {{ getEstatusTexto(item.estatus) }}
                    </v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                      title="Editar"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      x-small
                      @click="deleteItem(item)"
                      class="mr-2"
                      title="Borrar"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                    <!-- <v-btn
                      color="info"
                      x-small
                      @click="verHistorial(item)"
                      class="mr-2"
                      title="Historial"
                    >
                      <v-icon small>mdi-history</v-icon>
                    </v-btn> -->
                    <historial-servicio
                      :servicio="item"
                      ref="historialComponent"
                      @actualizar="initialize"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idservicios_sistemas }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="editedItem.servicio"
                label="Servicio"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="editedItem.cuenta"
                label="Cuenta"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="editedItem.id_plantel"
                :items="planteles"
                item-text="plantel"
                item-value="id_plantel"
                label="Plantel"
                filled
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_vencimiento"
                label="Fecha de Vencimiento"
                type="date"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_pago"
                label="Fecha de Pago"
                type="date"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col> -->

            <!-- <v-col cols="12" md="12">
              <v-select
                v-model="editedItem.recurrencia"
                :items="recurrencias"
                label="Recurrencia de pago"
                filled
                hide-details
                dense
              ></v-select>
            </v-col> -->

            <v-radio-group v-model="editedItem.recurrencia">
              <v-subheader>Recurrencia de pago:</v-subheader>
              <v-radio label="Diario" value="1"></v-radio>
              <v-radio label="Mensual" value="2"></v-radio>
              <v-radio label="Bimestral" value="3"></v-radio>
              <v-radio label="Anual" value="4"></v-radio>
            </v-radio-group>

            <v-col v-if="showFileInput" cols="12" md="12">
              <v-file-input
                small-chips
                v-model="files"
                accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                placeholder="Selecciona tus imagenes"
                prepend-icon="mdi-camera"
                @change="cargarFotos()"
              ></v-file-input>
            </v-col>

            <v-col v-if="editedItem.evidencia" cols="12" md="12">
              <v-img
                :src="url + editedItem.evidencia"
                contain
                max-height="100"
                @click="openImageDialog(editedItem.evidencia)"
                class="clickable-image"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" tile dark small @click="close">
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" tile dark small @click="save">
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el servicio?
                  <v-card-actions class="pt-6">
                    <v-btn color="error" text class="mr-2" @click="closeDelete()">
                      No, cancelar
                    </v-btn>
                    <v-btn color="#5C5C5C" dark class="elevation-6" @click="deleteItemConfirm()">
                      Si
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Alerta from '@/components/alertas/Alerta.vue'
import carga from '@/components/alertas/carga.vue'
import validarErrores from '@/mixins/validarErrores'
import funcionesExcel from '@/mixins/funcionesExcel'
import HistorialServicio from '../../components/HistorialServicio.vue'

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
    HistorialServicio,
  },

  data: () => ({
    parametros: {
      dialogError: false,
      mensaje: '',
      color: ''
    },
    estatus: 10,
    showFileInput: false,
    files: [],
    cargar: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    search: '',
    servicios_sistemas: [],
    planteles: [],

    editedItem: {
      idservicios_sistemas: 0,
      servicio: '',
      cuenta: '',
      fecha_vencimiento: '',
      /* fecha_pago: '', */
      monto_solicitado: 0,
      monto_pagado: 0,
      evidencia: '',
      id_requisicion: '',
      id_plantel: 0,
      recurrencia: 0,
      deleted: 0,
    },

    defaultItem: {
      idservicios_sistemas: 0,
      servicio: '',
      cuenta: '',
      fecha_vencimiento: '',
      /* fecha_pago: '', */
      monto_solicitado: 0,
      monto_pagado: 0,
      evidencia: '',
      id_requisicion: '',
      id_plantel: 0,
      recurrencia: 0,
      deleted: 0,
    },

    headers: [
      { text: "Servicio", value: "servicio" },
      { text: "Plantel", value: "plantel" },
      { text: "Fecha de Vencimiento", value: "fecha_vencimiento" },
      /* { text: "Fecha de Pago", value: "fecha_pago" }, */
      { text: "Recurrente", value: "recurrencia" },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

  }),

  computed: {
    ...mapGetters('login', ['getdatosUsuario']),
    formTitle() {
      return this.editedIndex === -1 ? 'Agregar Servicio' : 'Editar Servicio'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    async initialize() {
      this.cargar = true
      try {
        const response = await this.$http.get('servicios_sistemas.list')
        this.servicios_sistemas = response.data

        const responsePlanteles = await this.$http.get('servicios_sistemas.list.planteles');
        this.planteles = responsePlanteles.data;
      } catch (error) {
        this.validarError(error.response.data.message)
      } finally {
        this.cargar = false
      }
    },

    editItem(item) {
      this.editedIndex = this.servicios_sistemas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      /* this.showFileInput = true */
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.showFileInput = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItem(item) {
      this.editedIndex = this.servicios_sistemas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.cargar = true
      try {
        /* const formData = new FormData()
        formData.append('servicio', this.editedItem.servicio)
        formData.append('cuenta', this.editedItem.cuenta)
        formData.append('monto_solicitado', this.editedItem.monto_solicitado)
        formData.append('id_plantel', this.editedItem.id_plantel)
        formData.append('fecha_vencimiento', this.editedItem.fecha_vencimiento)
        formData.append('fecha_pago', this.editedItem.fecha_pago)
        formData.append('recurrencia', this.editedItem.recurrencia)
        formData.append('deleted', 1) */

        const datos = {
          servicio: this.editedItem.servicio,
          cuenta: this.editedItem.cuenta,
          monto_solicitado: this.editedItem.monto_solicitado,
          id_plantel: this.editedItem.id_plantel,
          fecha_vencimiento: this.editedItem.fecha_vencimiento,
          /* fecha_pago: this.editedItem.fecha_pago, */
          recurrencia: this.editedItem.recurrencia,
          deleted: 1
        };

        await this.$http.put(`servicios_sistemas.update.erp/${this.editedItem.idservicios_sistemas}`, datos /* formData */)
        this.closeDelete()
        await this.initialize()
      } catch (error) {
        this.validarError(error.response.data.message)
      } finally {
        this.cargar = false
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.cargar = true
      try {
        /* const formData = new FormData()
        formData.append('servicio', this.editedItem.servicio)
        formData.append('cuenta', this.editedItem.cuenta)
        formData.append('monto_solicitado', this.editedItem.monto_solicitado)
        formData.append('id_plantel', this.editedItem.id_plantel)
        formData.append('fecha_vencimiento', this.editedItem.fecha_vencimiento)
        formData.append('fecha_pago', this.editedItem.fecha_pago)
        formData.append('recurrencia', this.editedItem.recurrencia)
        formData.append('deleted', 0) */

        const datos = {
          servicio: this.editedItem.servicio,
          cuenta: this.editedItem.cuenta,
          monto_solicitado: this.editedItem.monto_solicitado,
          id_plantel: this.editedItem.id_plantel,
          fecha_vencimiento: this.editedItem.fecha_vencimiento,
          /* fecha_pago: this.editedItem.fecha_pago, */
          recurrencia: this.editedItem.recurrencia,
          deleted: 0
        };

        if (this.editedIndex > -1) {
          const response = await this.$http.put(
            `servicios_sistemas.update.erp/${this.editedItem.idservicios_sistemas}`,
            /* formData */
            datos
          )
          this.validarSuccess(response.data.message)
          await this.initialize()
          this.close()
        } else {
          await this.$http.post('servicios_sistemas.add.erp', this.editedItem)
          await this.initialize()
          this.close()
        }
      } catch (error) {
        this.validarError(error.response.data.message)
      } finally {
        this.cargar = false
      }
    },

    getRecurrenciaText(value) {
      const recurrencias = {
        1: "Diario",
        2: "Mensual",
        3: "Bimestral",
        4: "Anual",
    };
      return recurrencias[value] || "";
    },

    verHistorial(item) {
    // Aquí puedes implementar la lógica para mostrar el historial del item
    console.log(`Mostrando historial de ${item.nombre}`);
    },

    cargarFotos() {
      if (this.files < 1) {
        return
      }
    },

    getEstatusTexto(estatus) {
      const estatusMap = {
        0: 'En proceso',
        1: 'Aprobado',
        2: 'Con evidencia',
        3: 'Rechazado'
      }
      return estatusMap[estatus]
    },

    getEstatusColor(estatus) {
      const colorMap = {
        0: 'warning',
        1: 'success',
        2: 'info',
        3: 'error'
      }
      return colorMap[estatus]
    }
  }
}
</script>
