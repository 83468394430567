<template>
  <v-snackbar
    v-model="alertaNuevaVenta.estatus"
    right
    top
    vertical
    color="white"
    class="text-center mb-0 pt-2 black--text"
    :timeout="40000"
  >
  	<v-card class="elevation-0 white textoPoppins">
  		<v-card-title primary-title>
  			<v-spacer></v-spacer>
  			<v-img src="@/assets/nueva_venta.png" max-width="300"></v-img>
  			<v-spacer></v-spacer>
  		</v-card-title>
  		<v-card-text class="black--text text-center text-h6 textoPoppins">
	      <div 
	      	v-if="alertaNuevaVenta.mensaje"
	        style="white-space: pre-line"
          class="textoPoppins"
	      >
	        {{ alertaNuevaVenta.mensaje.mensaje }}
	      </div>
  		</v-card-text>
  	</v-card>
    <v-btn 
      color="red"
      absolute
      top
      small
      fab
      right
      @click="alertaNuevaVenta.estatus = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'alertaNuevaVenta',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',

    }),

    methods:{
      cerrar(){
      	this.alertaNuevaVenta.estatus = false
      },

    }
	}
</script>
<style scoped>
  .textoPoppins{
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
  }
</style>