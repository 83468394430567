import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default{
	namespaced: true,
	state:{
		conversaciones:[],
		conversacionesM:[],
		conversacionesG:[],
		conversacionesC:[],
		conversacionesF:[],
		conversacionesFast:[],
		conversacionesCRM:[],
		conversacionesCRMFast:[],
	},

	mutations:{
		CONVERSACIONES(state, value){
			state.conversaciones = value
		},

		CONVERSACIONESM(state, value){
			state.conversacionesM = value
		},

		CONVERSACIONESG(state, value){
			state.conversacionesG = value
		},

		CONVERSACIONESC(state, value){
			state.conversacionesC = value
		},

		CONVERSACIONESFAST(state, value){
			state.conversacionesFast = value
		},

		CONVERSACIONESCRM( state, value){
			state.conversacionesCRM = value
		},

		CONVERSACIONESCRMFAST( state, value){
			state.conversacionesCRMFast = value
		}
	},

	actions:{

		guardarConversaciones({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONES',conversaciones)
		},

		guardarConversacionesM({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESM',conversaciones)
		},

		guardarConversacionesG({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESG',conversaciones)
		},

		guardarConversacionesC({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESC',conversaciones)
		},

		guardarConversacionesFast({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESFAST',conversaciones)
		},

		guardarConversacionesCRM({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESCRM',conversaciones)
		},

		guardarConversacionesCRMFast({ commit, dispatch}, conversaciones){
			commit('CONVERSACIONESCRMFAST',conversaciones)
		},
  },

	getters:{
		getConversaciones(state){
		  return state.conversaciones
		},

		getConversacionesM(state){
		  return state.conversacionesM
		},

		getConversacionesG(state){
		  return state.conversacionesG
		},

		getConversacionesC(state){
		  return state.conversacionesC
		},

		getConversacionesFast(state){
		  return state.conversacionesFast
		},

		getConversacionesCRM(state){
		  return state.conversacionesCRM
		},

		getConversacionesCRMFast(state){
		  return state.conversacionesCRMFast
		},

	}
}