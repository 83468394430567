<template>
  <v-dialog
    v-model="dialogDiferencias.estatus"
    max-width="1200"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Reporte por vendedoras</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( vendedoras  , 'ASIGNACION_TWILIO')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row >
		  		<v-col cols="12" md="2">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="fechaini"
		  			  type="date"
		  			>
		  			</v-text-field>
		  		</v-col>

		  		<v-col cols="12" md="2">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="fechafin"
		  			  type="date"
		  			>
		  			</v-text-field>
		  		</v-col>

		  		<v-col cols="4">
            <v-radio-group
              v-model="escuela"
              row
              hide-details
            >
              <v-radio
                label="INBI"
                :value="1"
              ></v-radio>
              <v-radio
                label="FAST"
                :value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>

		  		<v-col cols="12" md="4">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>

		  		<v-col cols="12" md="4">
		  			Contactos twilio: {{ filterTwilio }} 
		  			<br/>Contactos CRM: {{ filterCrm }}
		  			<br/>Diferencia: {{ filterDiferencia }}
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="vendedoras"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >
					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="dialogDiferencias.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogDiferencias', 'nivel','leccion', 'contadorVendedoras'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

      defaultItem: {
        idtwilio_vendedoras:0,
				prioridad:'',
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
      },

			search:'',
      usuarios: [],
      headers:[
        { text: "De"            , value: "from"        },
        { text: "Para"          , value: "to"          },
        { text: "Telefono"      , value: "telefonocrm" },
        { text: "Creación"      , value: "fecha"       },
        { text: "Folio"         , value: "folio"       },
      ],

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			escuela: 1,

			filterTwilio:0,
			filterCrm:0,
			filterDiferencia:0,

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
      },

    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
    },

    methods: {


	    initialize() {
        this.cargar = true;
        this.vendedoras = []

        const payload = {
        	fechaini: this.fechaini,
					fechafin: this.fechafin,
					escuela : this.escuela,
        }

        return axios.post('twilio.diferencias', payload ).then(response => {
    			
    			this.vendedoras = response.data
 
    			this.filterTwilio     = this.vendedoras.length
					this.filterCrm        = this.vendedoras.filter( el => el.existe ).length
					this.filterDiferencia = this.filterTwilio - this.filterCrm

          this.cargar = false;

        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>